import React from "react";
import { Route, Routes } from "react-router-dom";

import { OrganizationList } from "./list";
import { EditOrganization } from "./edit";

export default () => {
  return (
    <Routes>
      <Route index element={<OrganizationList />} />
      <Route path="/:id" element={<EditOrganization />} />
    </Routes>
  );
};
