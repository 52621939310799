import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";

import api from "../../services/api";
import { TablePaginator } from "../../components/table";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "components/modal";

export const OrganizationList = () => {
  const [orgnizations, setOrganizations] = useState([]);
  const [productionsByOrganization, setProductionsByOrganization] = useState([]);
  const [displayedOrganizations, setDisplayedOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    api
      .get("/organizations/all")
      .then((res) => {
        if (res.ok) {
          setOrganizations(res.data);
          setProductionsByOrganization(res.productionsByOrganization);
          setDisplayedOrganizations(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search) setDisplayedOrganizations(orgnizations.filter((u) => u.name.toLowerCase().search(search.toLowerCase()) !== -1));
    else setDisplayedOrganizations(orgnizations);
  };

  if (!orgnizations || !displayedOrganizations) return <div>Chargement...</div>;

  return (
    <div className="bg-white rounded p-10">
      <CreateModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} />

      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800">Secoset - Société</h1>
        <div className="flex items-center gap-4">
          <div className="flex w-full flex-wrap items-center relative">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
          <button className="blue-button whitespace-nowrap" onClick={() => setIsCreateModalOpen(true)}>
            Créer une organisation
          </button>
        </div>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
          {displayedOrganizations.length > 1 ? displayedOrganizations.length + " sociétés" : displayedOrganizations.length + " société"}
        </span>
      </div>
      <TablePaginator
        data={displayedOrganizations.slice((page - 1) * pageSize, page * pageSize)}
        length={displayedOrganizations.length}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="w-[30%] table-header-title">Société</div>
            <div className="px-6 w-[15%] table-header-title">Propriétaire</div>
            <div className="px-6 w-[15%] table-header-title">Date de création</div>
            <div className="px-6 w-[15%] table-header-title">Nombre de projets</div>
            <div className="px-6 w-[15%] table-header-title">Nombre d’utilisateurs</div>
          </>
        )}
        renderItem={(item) => (
          <>
            <div className="px-6 w-[30%]">
              <Link className="w-full" to={`/organizations/${item._id.toString()}`}>
                {item.name}
              </Link>
            </div>
            <div className="px-6 w-[15%]">
              {/* <div className="flex items-center">
                  <div className="rounded-full bg-secoset-green-5 h-10 w-10 justify-center flex items-center text-xs text-secoset-green-900">
                    <span className="font-bold">
                      {item.admin?.firstName ? `${item.admin.firstName?.charAt(0).toLocaleUpperCase()}${item.admin.lastName?.charAt(0).toLocaleUpperCase()}` : "/"}
                    </span>
                  </div>
                  <div className="ml-8 flex flex-col">
                    <span className="font-semibold">{`${item.admin?.firstName || ""} ${item.admin?.lastName || ""}`}</span>
                    <span>{item.admin?.email}</span>
                  </div>
                </div> */}
              {item.director ? item.director : "Non renseigné"}
            </div>
            <div className="px-6 w-[15%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
            <div className="px-6 w-[15%]">{productionsByOrganization[item._id] || 0}</div>
            <div className="px-6 w-[15%]">{item.users?.length}</div>
          </>
        )}
      />
    </div>
  );
};

const CreateModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { ok, data } = await api.post("/organizations", { name });
      if (ok) {
        navigate(`/organizations/${data._id.toString()}`);
        toast.success("Organisation créée");
      }
    } catch (err) {
      toast.error("Erreur lors de la création de l'organisation");
      console.log(err);
    }
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
      <div className="p-8">
        <h1 className="text-2xl text-center font-semibold">Créer une organisation</h1>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="name">
              Nom
            </label>
            <input required className="input" name="name" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="flex justify-end">
            <button type="submit" className="blue-button mt-10 w-56" disabled={!name}>
              Creer
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
