import React from "react";

const ProgressBar = ({ title, percentage, max, value }) => {
  const percentageColor = (p) => {
    if (p < 70) return { text: "text-[#fff]", bg: "bg-green-400" };
    if (p < 85) return { text: "text-[#fff]", bg: "bg-[#fb8c00]" };
    if (p < 100) return { text: "text-[#fff]", bg: "bg-[#f4511e]" };
    if (p >= 100) return { text: "text-[#fff]", bg: "bg-[#bf360c]" };
  };

  return (
    <div className="">
      {title ? <span className="text-gray-600 text-sm mb-1">{title}</span> : null}
      <div className={`w-full bg-gray-200 h-3 rounded-lg overflow-hidden ${percentage > 120 ? "animate-bounce" : ""}`}>
        <div className="bg-[#f00] rounded-lg" style={{ width: `${Math.min(100, percentage)}%` }}>
          <div
            className={`flex justify-center items-center h-3 text-sm font-medium 
            ${percentageColor(Math.round(percentage)).bg}
            ${percentageColor(Math.round(percentage)).text}
            rounded-lg`}
          />
        </div>
      </div>
      <div className="flex items-start justify-between text-sm pt-1">
        <div className="px-2 text-xs flex items-center gap-x-1">{value.toFixed(2)}€</div>
        <div className="px-2">{percentage.toFixed(2)}%</div>
        <div className="px-2 text-xs">{max}€</div>
      </div>
    </div>
  );
};

export default ProgressBar;
