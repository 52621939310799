import React, { useEffect, useState } from "react";

import api from "../services/api";

//value should be user Object
export default ({ value, onChange, disabled = false, placeholder = "All users", name = "user", className = "w-[180px]" }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    searchUser();
  }, []);

  async function searchUser() {
    const res = await api.get("/secoset-user/admins");
    setUsers(res.data);
  }

  return (
    <div>
      <input
        list="user"
        value={search || userName(value)}
        className={`${className} bg-[#FFFFFF] text-[14px] text-[#212325] font-normal py-[9px] px-[14px] rounded-[10px] shadow-sm"`}
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value);
          if (e.target.value === "all") {
            return onChange();
          }
          const f = users.find((f) => e.target.value === userName(f));
          if (f) onChange(f);
          if (!e.target.value) onChange({});
        }}
      />
      <datalist
        id="user"
        name={name}
        disabled={disabled}
        className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
        value={value && value.firstName}>
        <option value="" disabled selected>
          Select user
        </option>
        {users
          .sort(function (a, b) {
            if (userName(a).toLowerCase() < userName(b).toLowerCase()) return -1;
            if (userName(a).toLowerCase() > userName(b).toLowerCase()) return 1;
            return 0;
          })
          .map((e) => (
            <option key={e._id} value={userName(e)} />
          ))}
      </datalist>
    </div>
  );
};

const userName = (user) => {
  if (!user || !user.firstName) return "";
  return `${user.firstName} ${user.lastName}`;
}
