export const ENVIRONMENT = process.env.NODE_ENV || "development";

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4001";
export const SECOSET_APP_URL = process.env.REACT_APP_SECOSET_URL || "http://localhost:3000";
export const SECO2_APP_URL = process.env.REACT_APP_SECO2_URL || "http://localhost:3000";

export const SECOSET_DEMO_USERID = "64550f8cd066ab0eff5d8422";
export const SECO2_DEMO_USERID = "6426fd540a1b1f0690c4fa3c";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
