import React, { useState, useEffect } from "react";
import { BsSearch, BsTrashFill, BsFillFileEarmarkTextFill, BsUpload } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../services/api";
import useStore from "../../services/zustand";
import { TablePaginator } from "../../components/table";
import Select from "components/select";

const planColors = {
  autonomy: "#7879E6",
  demo: "#E66136",
  supported: "#DF988A",
};

const statusColors = {
  ok: "#35ECB8",
  pending: "#7DA1AE",
  todo: "#F95620",
  error: "#EC5148",
  invalid: "#103C4B",
};

const statusLabels = {
  ok: "validés",
  pending: "en attente",
  todo: "à soumettre",
  error: "non conformes",
  invalid: "non validés",
};

export const ProductionList = () => {
  const [searchParams] = useSearchParams({});
  const { user } = useStore();
  const [productions, setProductions] = useState();
  const [displayedProductions, setDisplayedProductions] = useState([]);
  const [ecoManagers, setEcoManagers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [uploading, setUploading] = useState(false);

  const newFilterValues = { status: "pending" };
  if (user.role === "eco-manager") newFilterValues.ecoManager = user._id.toString();
  if (searchParams.get("organization")) newFilterValues.organization = searchParams.get("organization");
  const [filterValues, setFilterValues] = useState(newFilterValues);

  useEffect(() => {
    api
      .get("/organizations/all")
      .then((res) => {
        console.log(res);
        if (res.ok) {
          setOrganizations(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    api
      .get("/secoset-user/eco-managers")
      .then((res) => {
        if (res.ok) {
          setEcoManagers(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api
      .get(`/productions/all${buildQuery()}`)
      .then((res) => {
        setProductions(res.data);
        setDisplayedProductions(res.data);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filterValues]);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search) setDisplayedProductions(productions.filter((u) => u.title.toLowerCase().search(search.toLowerCase()) !== -1));
    else setDisplayedProductions(productions);
    setPage(1);
  };

  const handleChangeStatus = (production, status) => {
    api
      .put(`/productions/${production._id}`, { status })
      .then((res) => {
        if (res.ok) {
          toast.success("Statut mis à jour");
          const newProduction = productions.map((p) => (p._id.toString() === production._id.toString() ? res.data : p));
          setProductions(newProduction);
          setDisplayedProductions(newProduction);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la mise à jour du statut");
      });
  };

  const handleAddFiles = (e, production) => {
    console.log(e.target.files);
    if (uploading) return;
    setUploading(true);
    const files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    api
      .postFormData(`/productions/${production._id}/documents`, formData)
      .then((res) => {
        if (res.ok) {
          toast.success("Fichiers ajoutés");
          const newProduction = productions.map((p) => (p._id.toString() === production._id.toString() ? res.data : p));
          setProductions(newProduction);
          setDisplayedProductions(newProduction);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de l'ajout des fichiers");
      })
      .finally(() => setUploading(false));
  };

  const handleDeleteFile = (production, index) => {
    api
      .delete(`/productions/${production._id}/documents/${index}`)
      .then((res) => {
        if (res.ok) {
          toast.success("Fichier supprimé");
          const newProduction = productions.map((p) => (p._id.toString() === production._id.toString() ? res.data : p));
          setProductions(newProduction);
          setDisplayedProductions(newProduction);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la suppression du fichier");
      });
  };

  const buildQuery = () => {
    const query = Object.keys(filterValues)
      .filter((key) => filterValues[key])
      .map((key) => `${key}=${filterValues[key]}`)
      .join("&");
    return `${query ? `?${query}` : ""}`;
  };

  if (!productions) return <div>Chargement...</div>;
  return (
    <div className="bg-white rounded p-10">
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800 w-fit">Secoset - Projets en attente de labels</h1>

        <div className="flex flex-wrap items-center relative w-[360px]">
          <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
            <BsSearch />
          </span>
          <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
        </div>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
          {productions.length > 1 ? productions.length + " projets" : productions.length + " projet"}
        </span>
        <div className="w-[360px]">
          <Select
            options={ecoManagers.map((e) => ({ label: `${e.firstName} ${e.lastName} - ${e.email}`, value: e._id.toString() }))}
            value={filterValues.ecoManager}
            placeholder="Eco-manageur"
            onChange={(value) => {
              if (filterValues.ecoManager === value) setFilterValues({ ...filterValues, ecoManager: null });
              else setFilterValues({ ...filterValues, ecoManager: value });
            }}
          />
        </div>
        <div className="w-[360px]">
          <Select
            options={organizations.map((e) => ({ label: e.name, value: e._id.toString() }))}
            value={filterValues.organization}
            placeholder="Société"
            onChange={(value) => {
              if (filterValues.organization === value) setFilterValues({ ...filterValues, organization: null });
              else setFilterValues({ ...filterValues, organization: value });
            }}
          />
        </div>
      </div>

      <TablePaginator
        itemHeight="h-full"
        data={displayedProductions.slice((page - 1) * 10, page * 10)}
        length={displayedProductions.length}
        page={page}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[15%]">Projet</div>
            <div className="px-6 w-[15%]">Société</div>
            <div className="px-6 w-[10%]">Date</div>
            <div className="px-6 w-[45%]">Documents</div>
            <div className="px-6 w-[15%]">Statut</div>
          </>
        )}
        renderItem={(item) => (
          <>
            <div className="px-6 w-[15%]">
              <Link className="w-full font-bold" to={`/productions/${item._id.toString()}`}>
                {item.title}
              </Link>
            </div>
            {console.log(item)}
            <div className="px-6 w-[15%] line-clamp-2">{!item.organizations.length ? "Non renseignée" : item.organizations.map((o) => o.name).join(", ")}</div>

            <div className="px-6 w-[10%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
            <div className="px-6 w-[45%] flex items-center gap-2">
              <div className="w-[70%]">
                {item.documents.length > 0 ? (
                  item.documents.map((doc, i) => {
                    const j = doc.lastIndexOf("/");
                    const k = doc.lastIndexOf("?");
                    const filename = doc.substring(j + 1, k).replace(/%20/g, " ");

                    return (
                      <div className="flex items-center gap-2" key={i}>
                        <BsFillFileEarmarkTextFill />
                        <a className="w-[90%] truncate underline underline-offset-1 hover:font-semibold" href={`${doc}`} download>
                          {filename}
                        </a>
                        <button onClick={() => handleDeleteFile(item, i)}>
                          <BsTrashFill />
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-slate-700">Aucun document</p>
                )}
              </div>
              <div className="w-[30%]">
                <input type="file" name="documents" id="documents" multiple disabled={uploading} onChange={(e) => handleAddFiles(e, item)} hidden />
                <label className="cursor empty-button mr-0 flex items-center gap-2" htmlFor="documents">
                  <BsUpload className="mr-2" />
                  {uploading ? "Chargement..." : "Ajouter des documents"}
                </label>
              </div>
            </div>
            <div className="px-6 w-[15%] py-2">
              <Select
                options={[
                  {
                    value: "error",
                    label: "En erreur",
                  },
                  {
                    value: "rejected",
                    label: "Rejeté",
                  },
                  {
                    value: "ongoing",
                    label: "En cours",
                  },
                  {
                    value: "pending",
                    label: "En attente de label",
                  },
                  {
                    value: "validated",
                    label: "Labelisé",
                  },
                ]}
                value={item.status}
                onChange={(value) => handleChangeStatus(item, value)}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};
