import React from "react";

const Bilans = () => {
  return (
    <>
      <p>bilans Seco2</p>
    </>
  );
};

export default Bilans;
