import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../services/api";
import useStore from "../../services/zustand";

export default () => {
  const { setUser } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    api.post(`/auth/logout`);
    setUser(null);
    navigate("/signin");
  }, []);
  return <></>;
};
