import React from "react";
import { Paginator } from "./paginator";

export const TablePaginator = ({
  data,
  page,
  onPageChange,
  renderHeader,
  renderItem,
  length,
  headerHeight = "h-12",
  headerBackground = "bg-secoset-green-5",
  itemHeight = "h-16",
  itemBackground = "bg-white",
  loading = false,
  pageSize = 10,
  setPageSize = null,
}) => {
  const handlePageChange = (page) => {
    if (onPageChange) onPageChange(page);
  };

  if (!data || loading) return <div className="bg-gray-light p-4 text-center text-sm font-bold ">Chargement...</div>;
  if (!data.length) return <div className=" ml-60 w-full items-center justiy-center">Aucune donnée</div>;

  return (
    <div className="flex flex-col">
      <div className="w-full">
        <div className={`flex items-center border-b text-left text-sm font-bold  ${headerHeight} ${headerBackground}`}>{renderHeader()}</div>

        <div>
          {data.map((d, i) => {
            const item = renderItem(d, i);
            if (item)
              return (
                <div key={i} className={`flex items-center border-b border-b-gray-main text-left text-xs ${itemHeight} ${itemBackground}`}>
                  {renderItem(d, i)}
                </div>
              );
          })}
        </div>
      </div>
      <div className="mt-3">
        <Paginator page={page} setPage={handlePageChange} end={parseInt(length / pageSize) + (length % pageSize !== 0 && 1)} pageSize={pageSize} setPageSize={setPageSize} />
      </div>
    </div>
  );
};
