import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import validator from "validator";
import { BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";

import api from "../../services/api";
import { TablePaginator } from "../../components/table";
import { Modal } from "components/modal";
import Select from "components/select";
import useStore from "../../services/zustand";

const Administration = () => {
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [seletedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  useEffect(() => {
    api.get("/secoset-user/admins").then((res) => {
      setDisplayedUsers(res.data);
      setUsers(res.data);
    });
  }, [isUpdateModalOpen]);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search)
      setDisplayedUsers(
        users.filter(
          (u) =>
            (u.firstName && u.firstName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            (u.firstName && u.lastName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            u.email.toLowerCase().search(search.toLowerCase()) !== -1,
        ),
      );
    else setDisplayedUsers(users);
    setPage(1);
  };

  const handleUpdate = (user) => {
    const newUsers = users.map((u) => (u?._id === user._id ? user : u));
    setUsers(newUsers);
    setDisplayedUsers(newUsers);
    setPage(1);
  };

  if (!users) return <div>Chargement...</div>;

  return (
    <div className="bg-white rounded p-10">
      <CreateModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} setUsers={setUsers} />
      <UpdateModal open={isUpdateModalOpen} setOpen={setIsUpdateModalOpen} user={seletedUser} updateUser={handleUpdate} />
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800">Administration</h1>
        <div className="flex items-center gap-4">
          <div className="flex w-full flex-wrap items-center relative">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
          <button className="blue-button whitespace-nowrap" onClick={() => setIsCreateModalOpen(true)}>
            Créer un utilisateur
          </button>
        </div>
      </div>
      <TablePaginator
        data={displayedUsers.slice((page - 1) * 10, page * 10)}
        length={displayedUsers.length}
        page={page}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[50%]">Opérateurs</div>
            <div className="px-6 w-[40%]">Date de création</div>
          </>
        )}
        renderItem={(user) => (
          <>
            <div className="px-6 w-[50%]">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  setSelectedUser(user);
                  setIsUpdateModalOpen(true);
                }}>
                <div className="rounded-full bg-secoset-green-5 h-10 w-10 justify-center flex items-center text-xs text-secoset-green-900">
                  <span className="font-bold">{`${user.firstName?.charAt(0).toLocaleUpperCase()}${user.lastName?.charAt(0).toLocaleUpperCase()}`}</span>
                </div>
                <div className="ml-8 flex flex-col">
                  <span className="font-semibold">{`${user.firstName} ${user.lastName}`}</span>
                  <span>{user.email}</span>
                </div>
              </div>
            </div>
            <div className="px-6 w-[40%] text-gray-600 text-sm uppercase font-normal">{new Date(user.createdAt).toLocaleDateString("fr")}</div>
          </>
        )}
      />
      <button className="text-xs text-gray-200 mt-3" onClick={() => methodDoesNotExist()}>
        Break the world
      </button>
    </div>
  );
};

const CreateModal = ({ open, setOpen, setUsers }) => {
  const { user: auth } = useStore();

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.firstName)) errors.firstName = "Le prénom est requis";
    if (validator.isEmpty(values.email)) errors.email = "L'email' est requis";
    if (validator.isEmpty(values.password)) errors.password = "Le mot de passe est requis";
    setErrors(errors);
    return !errors.firstName && !errors.email && !errors.password;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    api.post("/secoset-user/admins", values).then((res) => {
      setUsers((users) => [...users, res.data]);
      setOpen(false);
      toast.success("Utilisateur créé");
    });
  };

  const userOptions =
    auth.role === "admin"
      ? [
          { label: "Administrateur", value: "admin" },
          { label: "Eco-manager", value: "eco-manager" },
        ]
      : [{ label: "Eco-manager", value: "eco-manager" }];

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
      <div className="p-8">
        <h1 className="text-2xl text-center font-semibold">Créer un utilisateur</h1>
        <Formik initialValues={{ firstName: "", lastName: "", email: "", password: "", role: "eco-manager" }} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="grid grid-cols-2 gap-4 px-16 pt-8">
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="firstName">
                  Prénom
                </label>
                <input className="input" name="firstName" value={values.firstName} onChange={handleChange} />
                {errors.firstName && <div className="flex items-center text-sm text-red-main">{errors.firstName}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="lastName">
                  Nom
                </label>
                <input className="input" name="lastName" value={values.lastName} onChange={handleChange} />
                {errors.lastName && <div className="flex items-center text-sm text-red-main">{errors.lastName}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="email">
                  Email
                </label>
                <input className="input" name="email" value={values.email} onChange={handleChange} />
                {errors.email && <div className="flex items-center text-sm text-red-main">{errors.email}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="password">
                  Mot de passe
                </label>
                <input className="input" type="password" name="password" value={values.password} onChange={handleChange} />
                {errors.password && <div className="flex items-center text-sm text-red-main">{errors.password}</div>}
              </div>

              <div className="flex flex-col mb-10">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="email">
                  Role
                </label>

                <Select options={userOptions} value={values.role} onChange={(value) => setFieldValue("role", value)} />
                {errors.role && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.role}
                  </div>
                )}
              </div>
              <div />
              <button type="button" className="empty-button" onClick={() => setOpen(false)}>
                Annuler
              </button>
              <button type="submit" className="blue-button" disabled={Object.keys(errors).length !== 0}>
                Creer
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const UpdateModal = ({ open, setOpen, user, updateUser }) => {
  const { user: auth } = useStore();
  if (!user) return null;

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.firstName)) errors.firstName = "Le prénom est requis";
    if (validator.isEmpty(values.email)) errors.email = "L'email' est requis";
    if (validator.isEmpty(values.password)) errors.password = "Le mot de passe est requis";
    setErrors(errors);
    return !errors.firstName && !errors.email && !errors.password;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    if (values.password === "not-so-secret") delete values.password;

    api.put(`/secoset-user/${user._id.toString()}`, values).then((res) => {
      updateUser(res.data);
      setOpen(false);
      toast.success("Utilisateur modifié");
    });
  };

  const handleDelete = async () => {
    if (!window.confirm("Etes vous sur de supprimer le user ?")) return;
    api.delete(`/secoset-user/${user._id.toString()}`).then(() => {
      setOpen(false);
      toast.success("Utilisateur supprimé");
    });
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
      <div className="p-8">
        <h1 className="text-2xl text-center font-semibold">Modifier un utilisateur</h1>
        <Formik initialValues={{ ...user, password: "not-so-secret" }} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="grid grid-cols-2 gap-4 px-16 pt-8">
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="firstName">
                  Prénom
                </label>
                <input className="input" name="firstName" value={values.firstName} onChange={handleChange} />
                {errors.firstName && <div className="flex items-center text-sm text-red-main">{errors.firstName}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="lastName">
                  Nom
                </label>
                <input className="input" name="lastName" value={values.lastName} onChange={handleChange} />
                {errors.lastName && <div className="flex items-center text-sm text-red-main">{errors.lastName}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="email">
                  Email
                </label>
                <input className="input" name="email" value={values.email} onChange={handleChange} />
                {errors.email && <div className="flex items-center text-sm text-red-main">{errors.email}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="password">
                  Mot de passe
                </label>
                <input className="input" type="password" name="password" value={values.password} onChange={handleChange} />
                {errors.password && <div className="flex items-center text-sm text-red-main">{errors.password}</div>}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="email">
                  Role
                </label>

                <Select
                  readOnly={auth.role !== "admin"}
                  options={[
                    { label: "Administrateur", value: "admin" },
                    { label: "Eco-manager", value: "eco-manager" },
                  ]}
                  value={values.role}
                  onChange={(value) => setFieldValue("role", value)}
                />
                {errors.role && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.role}
                  </div>
                )}
              </div>
              <div />
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="cost">
                  Coût par jour
                </label>
                <input className="input" type="number" name="cost" value={values.cost} onChange={handleChange} />
                {errors.cost && <div className="flex items-center text-sm text-red-main">{errors.cost}</div>}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="tjm">
                  Prix vendu par jour
                </label>
                <input className="input" type="number" name="tjm" value={values.tjm} onChange={handleChange} />
                {errors.tjm && <div className="flex items-center text-sm text-red-main">{errors.tjm}</div>}
              </div>

              <button type="button" className="red-button mt-10" onClick={handleDelete}>
                Supprimer
              </button>
              <button type="submit" className="blue-button mt-10" disabled={Object.keys(errors).length !== 0}>
                Mettre à jour
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default Administration;
