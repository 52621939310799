import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Informations from "./informations";
import Criteres from "./criteres";
import Activites from "./activites";
import { classNames } from "../../../utils";
import { IoInformationCircleOutline, IoCheckmarkCircleOutline, IoCalendarOutline } from "react-icons/io5";
import api from "../../../services/api";

const Index = () => {
  const [production, setProduction] = useState(null);

  const { id, tab } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const listTab = ["informations", "criteres", "activites"];
    if (!listTab.includes(tab)) navigate(`/productions/${id}/informations`);
  }, [tab]);

  useEffect(() => {
    api.get(`/productions/${id}`).then((res) => {
      if (res.ok) setProduction(res.data);
    });
  }, [id]);

  if (!production) return <div>chargement...</div>;

  const navigations = [
    { title: "Informations", tab: "informations", Icon: IoInformationCircleOutline },
    { title: "Critères", tab: "criteres", Icon: IoCheckmarkCircleOutline },
    { title: "Activités", tab: "activites", Icon: IoCalendarOutline },
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <h1 className="font-bold text-2xl text-cyan-800 m-1">Secoset - Projet {production.title}</h1>
      <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200">
        {navigations.map((nav) => (
          <TabItem key={nav.tab} title={nav.title} tab={nav.tab} onClick={() => navigate(`/productions/${id}/${nav.tab}`)} active={tab === nav.tab} Icon={nav.Icon} />
        ))}
      </nav>
      <div className="my-2" />
      <div className="bg-white">
        {tab === "informations" && <Informations />}
        {tab === "criteres" && <Criteres />}
        {tab === "activites" && <Activites />}
      </div>
    </div>
  );
};

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "border-cyan-800" : "",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-cyan-800" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className={classNames(active ? "text-cyan-800" : "text-gray-400 group-hover:text-gray-500", "flex items-center gap-2 font-bold")}>{title}</div>
    {Badge ? Badge : null}
  </button>
);

export default Index;
