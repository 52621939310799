import React from "react";
import { Route, Routes } from "react-router-dom";

import { ProductionList } from "./list";
import { EditProduction } from "./edit";

export default () => {
  return (
    <Routes>
      <Route index element={<ProductionList />} />
      <Route path="/:id" element={<EditProduction />} />
    </Routes>
  );
};
