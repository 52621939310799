import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { RiHistoryLine, RiErrorWarningLine, RiCheckboxCircleLine, RiCloseCircleLine, RiCheckboxBlankCircleLine } from "react-icons/ri";
import { Link, useSearchParams } from "react-router-dom";

import { TablePaginator } from "../../components/table";
import Select from "components/select";
import ProgressBar from "components/ProgressBar";

import api from "../../services/api";
import useStore from "../../services/zustand";

import { PRODUCTION_PLAN, PRODUCTION_PLAN_COLORS } from "../../utils/constants";

const STATUS_COLORS = {
  ok: "#35ECB8",
  pending: "#7DA1AE",
  todo: "#F95620",
  error: "#EC5148",
  invalid: "#103C4B",
};

const STATUS_ICONS = {
  ok: <RiCheckboxCircleLine />,
  pending: <RiHistoryLine />,
  todo: <RiCheckboxBlankCircleLine />,
  error: <RiCloseCircleLine />,
  invalid: <RiErrorWarningLine />,
};

export const ProductionList = () => {
  const [searchParams] = useSearchParams({});
  const { user } = useStore();
  const [productions, setProductions] = useState();
  const [displayedProductions, setDisplayedProductions] = useState([]);
  const [ecoManagers, setEcoManagers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // [10, 25, 50, 100
  const [sort, setSort] = useState("createdAt");
  const newFilterValues = {};
  if (user.role === "eco-manager") newFilterValues.ecoManager = user._id.toString();
  if (searchParams.get("organization")) newFilterValues.organization = searchParams.get("organization");
  const [filterValues, setFilterValues] = useState(newFilterValues);

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setDate(twoMonthsAgo.getDate() - 60);

  useEffect(() => {
    api
      .get("/organizations/all")
      .then((res) => {
        if (res.ok) {
          setOrganizations(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    api
      .get("/secoset-user/eco-managers")
      .then((res) => {
        if (res.ok) {
          setEcoManagers(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    api
      .get(`/productions/all${buildQuery()}`)
      .then((res) => {
        setProductions(res.data);
        setDisplayedProductions(res.data);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filterValues, pageSize]);

  const handleSearch = (e) => {
    e.preventDefault();
    // const search = e.target.value;

    if (e.target.value) {
      // remove accents and special characters
      const search = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setDisplayedProductions(productions.filter((u) => u.title.toLowerCase().search(search.toLowerCase()) !== -1));
    } else setDisplayedProductions(productions);
    setPage(1);
  };

  const buildQuery = () => {
    const query = Object.keys(filterValues)
      .filter((key) => filterValues[key])
      .map((key) => `${key}=${filterValues[key]}`)
      .join("&");

    return `${query ? `?${query}` : ""}`;
  };

  if (!productions) return <div>Chargement...</div>;
  return (
    <div className="bg-white rounded p-10">
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800 w-fit">Secoset - Projets</h1>

        <div className="flex flex-wrap items-center relative w-[360px]">
          <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
            <BsSearch />
          </span>
          <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
        </div>
      </div>
      <div className="flex flex-wrap items-center mb-4 justify-between">
        <div className="flex items-center gap-5">
          <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
            {productions.length > 1 ? productions.length + " projets" : productions.length + " projet"}
          </span>
          <div className="w-40">
            <Select
              options={ecoManagers.map((e) => ({ label: `${e.firstName} ${e.lastName} - ${e.email}`, value: e._id.toString() }))}
              value={filterValues.ecoManager}
              placeholder="Eco-manageur"
              onChange={(value) => {
                if (filterValues.ecoManager === value) setFilterValues({ ...filterValues, ecoManager: null });
                else setFilterValues({ ...filterValues, ecoManager: value });
              }}
            />
          </div>
          <div className="w-40">
            <Select
              options={organizations.map((e) => ({ label: e.name, value: e._id.toString() }))}
              value={filterValues.organization}
              placeholder="Société"
              onChange={(value) => {
                if (filterValues.organization === value) setFilterValues({ ...filterValues, organization: null });
                else setFilterValues({ ...filterValues, organization: value });
              }}
            />
          </div>
          <div className="w-40">
            <Select
              options={[
                {
                  value: "error",
                  label: "En erreur",
                },
                {
                  value: "rejected",
                  label: "Rejeté",
                },
                {
                  value: "ongoing",
                  label: "En cours",
                },
                {
                  value: "pending",
                  label: "En attente de label",
                },
                {
                  value: "validated",
                  label: "Labelisé",
                },
              ]}
              value={filterValues.status}
              placeholder="Statut"
              onChange={(value) => {
                if (filterValues.status === value) setFilterValues({ ...filterValues, status: null });
                else setFilterValues({ ...filterValues, status: value });
              }}
            />
          </div>
          <div className="w-40">
            <Select
              options={Object.entries(PRODUCTION_PLAN).map(([key, value]) => ({ label: value, value: key }))}
              value={filterValues.plan}
              placeholder="Plan"
              onChange={(value) => {
                if (filterValues.plan === value) setFilterValues({ ...filterValues, plan: null });
                else setFilterValues({ ...filterValues, plan: value });
              }}
            />
          </div>
          <div className="w-40">
            <Select
              options={[
                {
                  value: oneWeekAgo,
                  label: "1 semaine",
                },
                {
                  value: twoWeeksAgo,
                  label: "2 semaines",
                },
                {
                  value: oneMonthAgo,
                  label: "1 mois",
                },
                {
                  value: twoMonthsAgo,
                  label: "2 mois",
                },
                {
                  value: null,
                  label: "Tout",
                },
              ]}
              value={filterValues.createdAt}
              placeholder="Créé il y a"
              onChange={(value) => {
                if (filterValues.createdAt === value) setFilterValues({ ...filterValues, createdAt: null });
                else setFilterValues({ ...filterValues, createdAt: value });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span>Trier par</span>
          <select className="" onChange={(e) => setSort(e.target.value)}>
            <option value="createdAt">Date de création</option>
            <option value="title">Nom du projet</option>
          </select>
        </div>
      </div>

      <TablePaginator
        itemHeight="h-full"
        data={displayedProductions
          .sort((a, b) => {
            if (sort === "createdAt") return new Date(b.createdAt) - new Date(a.createdAt);
            else if (sort === "title") return a.title.localeCompare(b.title);
            else return 0;
          })
          .slice((page - 1) * pageSize, page * pageSize)}
        length={displayedProductions.length}
        page={page}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[15%]">Projet</div>
            <div className="px-6 w-[15%]">Société</div>
            <div className="px-6 w-[15%]">Plan</div>
            <div className="px-6 flex-1">Budget</div>
            <div className="px-6 flex-1">Critères</div>
          </>
        )}
        renderItem={(item) => {
          return (
            <>
              <div className="px-6 w-[15%] flex flex-col gap-2">
                {new Date(item.createdAt) > oneWeekAgo && <span className="bg-secoset-green-5 rounded w-fit p-1 font-medium text-xs text-secoset-green-700">Nouveau</span>}
                <Link className="w-full font-bold text-sm" to={`/productions/${item._id.toString()}/informations`}>
                  {item.title}
                </Link>
              </div>
              <div className="px-6 w-[15%] line-clamp-2">{!item.organizations.length ? "Non renseignée" : item.organizations.map((o) => o.name).join(", ")}</div>
              <div className="px-6 w-[15%]">
                <p className="text-center border-dashed border-2 p-2" style={{ borderColor: PRODUCTION_PLAN_COLORS[item.plan], color: PRODUCTION_PLAN_COLORS[item.plan] }}>
                  {PRODUCTION_PLAN[item.plan] || item.plan}
                </p>
              </div>
              <div className="px-6 flex-1">
                <Calculations production={item} />
              </div>
              <div className="px-6 flex-1">
                <Criterias criterias={item.criterias} />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

const Calculations = ({ production }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?projectTitle=${encodeURIComponent(production.title)}`);
      setActivities(data);
    })();
  }, []);

  if (!activities) return <div className="p-4 text-sm">Chargement...</div>;

  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);

  if (!production.budget) return <div className="text-sm font-bold">Consumption: {cost}€</div>;

  return <ProgressBar percentage={((cost || 0) * 100) / (production.budget || 1)} max={production.budget} value={cost} />;
};

const Criterias = ({ criterias }) => {
  const allStatus = ["ok", "pending", "todo", "error", "invalid"];
  const bilan = {};
  criterias.forEach((e) => (bilan[e.status] = (bilan[e.status] ? bilan[e.status] : 0) + 1));

  return (
    <div className="py-3 text-xs">
      <p>{`${((bilan["ok"] || 0) * 100) / criterias.length}% complétés (${bilan["ok"] || 0}/${criterias.length})`}</p>
      <div className="w-full bg-secoset-green-5 h-2 rounded-md relative z-0 mb-4 mt-2">
        <div className="bg-secoset-green-700 h-2 rounded-md absolute bottom-0 left-0 z-1" style={{ width: `${((bilan["ok"] || 0) * 100) / criterias.length}%` }}></div>
      </div>
      <div className="grid grid-cols-3 gap-2">
        {allStatus.map((status, i) => {
          return (
            <p style={{ color: STATUS_COLORS[status] }} key={`status-${i}`} className="text-sm flex items-center gap-1">
              {`${bilan[status] ? bilan[status] : 0}/${criterias.length}`}
              {STATUS_ICONS[status]}
            </p>
          );
        })}
      </div>
    </div>
  );
};
