import React from "react";

const Account = () => {
  return (
    <>
      <p>account</p>
    </>
  );
};

export default Account;
