import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SelectMonth from "components/selectMonth";

import api from "../../../services/api";
import { getDaysInMonth } from "utils";

export default function EditProduction() {
  const [production, setProduction] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    api.get(`/productions/${id}`).then((res) => {
      if (res.ok) setProduction(res.data);
    });
  }, [id]);

  if (!production) return <h1>Chargement...</h1>;

  return (
    <div className="bg-white rounded p-10">
      <Activities production={production} />
    </div>
  );
}

const Activities = ({ production }) => {
  const [activities, setActivities] = useState([]);
  const [date, setDate] = useState();
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (!production || !date) return;

    (async () => {
      let from = new Date(date);
      from.setDate(1);
      setDays(getDaysInMonth(from.getMonth(), from.getFullYear()));
      let date_to = new Date(date);
      date_to.setMonth(date_to.getMonth() + 1);
      date_to.setDate(0);
      const { data } = await api.get(`/activity?dateFrom=${from.getTime()}&dateTo=${date_to.getTime()}&projectTitle=${encodeURIComponent(production.title)}`);
      setActivities(data);
    })();
  }, [date, production]);

  const getTotal = () => {
    return (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);
  };

  return (
    <div>
      <h2 className="text-xl font-bold text-black mt-3 mb2">Activities</h2>
      <div className="py-3 text-black">
        <div className="w-full bg-[#ffffff] border border-[#E5EAEF] rounded-[16px] overflow-hidden">
          <div className="flex gap-5 p-2">
            <SelectMonth
              start={0}
              indexDefaultValue={0}
              value={date}
              onChange={(e) => {
                setActivities([]);
                setDate(e.target.value);
              }}
              showArrows
            />
          </div>
          <div className="mt-2 rounded-lg bg-white">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-sm font-bold text-left w-28">Users</th>
                    {days.map((e) => {
                      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                      const _date = new Date(e);
                      const day = _date.getDay();
                      const weekday = days[day];
                      const date = _date.getDate();
                      return (
                        <th className={`w-5 border border-[#E5EAEF] text-xs font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-white"}`} key={e} day={day}>
                          <div>{weekday}</div>
                          <div>{date}</div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b border-r border-[#E5EAEF]">
                    <th className="px-2 text-right text-xs font-bold italic">{`Total ${getTotal()} days`}</th>
                    {days.map((e, i) => {
                      const v = activities.reduce((acc, a) => {
                        if (!a.detail[i]) return acc;
                        return acc + a.detail[i].value;
                      }, 0);
                      return <Field key={i} value={v.toFixed()} disabled />;
                    })}
                  </tr>
                  {activities
                    .sort((a, b) => b.total - a.total)
                    .map((e) => {
                      return (
                        <React.Fragment key={`${e.user}`}>
                          <tr className="border-t border-b border-r border-[#E5EAEF]" key={`1-${e._id}`}>
                            <th className="w-[100px] border-t border-b border-r text-[12px] font-bold text-[#212325] text-left">
                              <div className="flex flex-1 items-center justify-between gap-1 px-2">
                                <div className="flex flex-1 items-center justify-start gap-1">
                                  <img
                                    className="relative z-30 inline object-cover w-[25px] h-[25px] border border-white rounded-full"
                                    src={e?.userAvatar}
                                    alt={`avatar ${e?.user}`}
                                  />
                                  <div className="flex-1">
                                    <div>{e.userName}</div>
                                    <div className="text-md italic font-normal text-gray-500 text-right">{(e.total / 8).toFixed(2)} days</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            {e.detail.map((f, j) => {
                              return <Field key={`${e.user} ${j}`} value={f.value || 0} />;
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Field = ({ value = "-", ...rest }) => {
  let bgColor = "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] ${bgColor} ${textColor}`}>
      <div className={`text-center font-normal `} {...rest}>
        {value}
      </div>
    </th>
  );
};
