import { useEffect, useState } from "react";

import { Doughnut } from "./graph";
import { ProgressBar } from ".";

import ProgressBarComponent from "components/ProgressBar";
import api from "../../services/api";

export const statusLabels = {
  ongoing: "En cours",
  validated: "Validé",
  rejected: "Invalidé",
  error: "Non conforme",
  pending: "Soumis, en attente",
};
export const statusColors = {
  ongoing: "#ffdd4a",
  validated: "#35ecb8",
  rejected: "#fe5e33",
  error: "#ff3333",
  pending: "#97bac7",
};

export const ProjectSynthesis = ({ productions }) => {
  const [criteriaSynthesis, setCriteriaSynthesis] = useState();
  const [productionSynthesis, setProductionSynthesis] = useState();
  const [doughnutData, setDoughnutData] = useState();

  useEffect(() => {
    const { criteriaSynthesis, productionSynthesis } = buildSynthesis(productions);
    const doughnutData = buildDoughnutData(productionSynthesis);
    setCriteriaSynthesis(criteriaSynthesis);
    setProductionSynthesis(productionSynthesis);
    setDoughnutData(doughnutData);
  }, [productions]);

  const buildDoughnutData = (productionSynthesis) => {
    const labels = [];
    const datasets = { data: [], backgroundColor: [], borderColor: [], borderWidth: 1 };
    Object.entries(productionSynthesis).forEach(([statusKey, statusSum]) => {
      if (!Object.keys(statusLabels).includes(statusKey)) return;
      labels.push(statusLabels[statusKey] + " : " + statusSum);
      datasets.data.push(statusSum);
      datasets.backgroundColor.push(statusColors[statusKey]);
      datasets.borderColor.push(statusColors[statusKey]);
    });
    return { labels, datasets: [datasets] };
  };

  const buildSynthesis = (productions) => {
    const productionSynthesis = { validated: 0, ongoing: 0, rejected: 0, pending: 0, error: 0 };
    const criteriaSynthesis = { valid: 0, pending: 0, todo: 0, error: 0, invalid: 0, total: 0 };
    productions.forEach((production) => {
      if (production.status === "validated") productionSynthesis.validated++;
      else if (production.status === "ongoing") productionSynthesis.ongoing++;
      else if (production.status === "pending") productionSynthesis.pending++;
      else if (production.status === "error") productionSynthesis.error++;
      else if (production.status === "rejected") productionSynthesis.rejected++;
      productionSynthesis.total++;
      production.criterias.forEach((criteria) => {
        if (criteria.status === "ok") criteriaSynthesis.valid++;
        else if (criteria.status === "pending") criteriaSynthesis.pending++;
        else if (criteria.status === "todo") criteriaSynthesis.todo++;
        else if (criteria.status === "error") criteriaSynthesis.error++;
        else if (criteria.status === "invalid") criteriaSynthesis.invalid++;
        criteriaSynthesis.total++;
      });
    });
    return { criteriaSynthesis, productionSynthesis };
  };

  if (!criteriaSynthesis || !productionSynthesis) return <div>Chargement...</div>;
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 items-stretch gap-5">
        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Mes projets</h1>
          <div className="mb-8 grid grid-cols-2">
            <div>
              <p>Projets</p>
              <div className="flex h-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-mid" />
                <p>{productions.length}</p>
              </div>
            </div>
            <div>
              <p>Projets labellisés</p>
              <div className="flex h-16 w-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-dark" />
                <p>{productions.reduce((acc, a) => acc + (a.status === "validated" ? 1 : 0), 0)}</p>
              </div>
            </div>
          </div>
          <div>
            <p className="mb-4">Budget</p>
            <Calculations projects={productions} />
          </div>
        </section>

        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Statuts de labellisation</h1>
          <div className="m-4 ">
            <Doughnut data={doughnutData} />
          </div>
        </section>
      </div>
    </div>
  );
};

const Calculations = ({ projects }) => {
  const [budget, setBudget] = useState();
  const [cost, setCost] = useState();

  useEffect(() => {
    if (!projects || !projects.length) return setBudget(0);
    (async () => {
      const projectId = projects?.map((p) => p._id) || [];
      console.log("✌️  projectId", projectId);

      const { data } = await api.post(`/activity/search`, { projectId });
      console.log("✌️  data", data);
      const _cost = data.reduce((acc, cur) => acc + cur.cost, 0);
      setCost(_cost);
      const _budget = projects.reduce((acc, cur) => acc + cur.budget, 0);
      setBudget(_budget);
    })();
  }, [projects]);

  if (!budget || !cost) return <div className="p-4 text-sm">Chargement...</div>;

  return <ProgressBarComponent percentage={((cost || 0) * 100) / (budget || 1)} max={budget} value={cost} />;
};
