import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { BsSearch } from "react-icons/bs";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { IoChevronDown, IoChevronUp } from "react-icons/io5";

import { ErrorIcon, InvalidIcon, PendingIcon, TodoIcon, ValidIcon } from "../../components/Icons";
import { SECOSET_APP_URL } from "../../config";
import { PRODUCTION_PLAN, PRODUCTION_PLAN_COLORS } from "utils/constants";
import ProgressBarComponent from "components/ProgressBar";
import api from "../../services/api";
import Select from "components/select";

const projectStatus = {
  all: "Tous les status",
  validated: "Validé",
  pending: "En attente",
  error: "Erreur",
  ongoing: "En cours",
  rejected: "Rejeté",
};

export const ProjectList = ({ productions, thematics }) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [displayedProductions, setDisplayedProductions] = useState([]);

  useEffect(() => {
    setDisplayedProductions(productions);
  }, [productions]);

  useEffect(() => {
    if (search && status !== "all") setDisplayedProductions(productions.filter((p) => p.title.toLowerCase().search(search.toLowerCase()) !== -1 && p.status === status));
    else if (status !== "all") setDisplayedProductions(productions.filter((p) => p.status === status));
    else if (search) setDisplayedProductions(productions.filter((p) => p.title.toLowerCase().search(search.toLowerCase()) !== -1));
    else setDisplayedProductions(productions);
  }, [status, search]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <section className="bg-white px-1 py-6">
      <div className="grid grid-cols-12 bg-white px-8 py-6 gap-3">
        <div className="col-span-8 relative flex flex-wrap items-center">
          <span className="absolute left-2 z-10 w-8 items-center justify-center bg-transparent p-3 text-slate-700">
            <BsSearch />
          </span>
          <input onChange={handleSearch} placeholder="Rechercher par titre..." className="input pl-10" />
        </div>
        <div className="col-span-4">
          <Select options={Object.entries(projectStatus).map(([k, v]) => ({ label: v, value: k }))} value={status} placeholder="status" onChange={(value) => setStatus(value)} />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {displayedProductions.map((p, i) => (
          <Project key={i} production={p} thematics={thematics} />
        ))}
      </div>
    </section>
  );
};

const Project = ({ production, thematics }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const validCount = production.criterias.reduce((acc, a) => acc + (["ok", "pending", "error", "invalid"].includes(a.status) ? 1 : 0), 0);
    const progress = (validCount * 100) / production.criterias.length;
    setProgress(progress);
  }, [production]);

  return (
    <div className="border-y border-blue-gray bg-white px-8 py-6">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-lg font-bold text-blue-dark">{production.title}</h1>
        <div className="flex items-center gap-2">
          <a className="text-sky-400 font-bold" href={`${SECOSET_APP_URL}/saas/productions/${production._id.toString()}`} target="_blank">
            Voir le projet sur Secoset
          </a>
          <button className="rounded bg-blue-dark px-4 py-2 text-white" type="button" onClick={() => navigate(`/productions/${production._id}`)}>
            Ouvrir
          </button>
          <button className="" type="button" onClick={() => setOpen((e) => !e)}>
            {open ? <IoChevronUp /> : <IoChevronDown />}
          </button>
        </div>
      </div>

      <div className="m-3 gap-2 ">
        <div>
          <div className="mb-4 flex w-full items-center gap-4">
            <div className="flex-1">
              <ProgressBar percentage={progress} title="Projet" />
            </div>
            <div className="flex-1">
              <Calculations production={production} />
            </div>
          </div>
          <div className="grid grid-cols-4">
            <div>
              <p className="text-gray-500 text-xs uppercase">Direction de production</p>
              <p>{production.productionDirector}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs uppercase">Réalisation</p>
              <p>{production.director}</p>
            </div>
            <div>
              <p className="text-gray-500 text-xs uppercase">Dates du tournage</p>
              <p>
                Du {new Date(production.shootingStart).toLocaleDateString("fr")} au {new Date(production.shootingEnd).toLocaleDateString("fr")}
              </p>
            </div>
            <div>
              <p
                className="text-center border-dashed border-2 p-2"
                style={{ borderColor: PRODUCTION_PLAN_COLORS[production.plan], color: PRODUCTION_PLAN_COLORS[production.plan] }}>
                {PRODUCTION_PLAN[production.plan] || production.plan}
              </p>
            </div>
          </div>
        </div>
      </div>
      {open ? (
        <div className="grid grid-cols-2 gap-x-6 gap-y-2 rounded-sm bg-blue-light p-4 ring-1 ring-blue-mid ring-offset-blue-light ">
          {thematics.map((thematic, i) => {
            const criterias = production.criterias.filter((criteria) => criteria.thematic === thematic._id);
            return (
              <div className="flex items-center justify-end" key={i}>
                <p className="whitespace-nowrap text-right text-sm text-blue-dark">{thematic.title}</p>
                <div className="flex">
                  {criterias.map((criteria, i) => (
                    <div key={i}>
                      {
                        {
                          ok: <ValidIcon className="h-5 w-5 text-green-500" />,
                          pending: <PendingIcon className="h-5 w-5 text-blue-dark" />,
                          todo: <TodoIcon className="h-5 w-5 text-blue-mid" />,
                          error: <ErrorIcon className="h-5 w-5 text-orange-600" />,
                          invalid: <InvalidIcon className="h-5 w-5 text-red-500" />,
                        }[criteria.status]
                      }
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const Calculations = ({ production: project }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?projectId=${project._id.toString()}`);
      setActivities(data);
    })();
  }, [project]);

  if (!activities) return <div className="p-4 text-sm">Chargement...</div>;

  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);

  if (!project.budget) return <div className="text-sm font-bold">Consumption: {cost}€</div>;

  return <ProgressBarComponent percentage={((cost || 0) * 100) / (project.budget || 1)} max={project.budget} value={cost} title="Budget" />;
};

const ProgressBar = ({ title, percentage }) => {
  return (
    <div className="">
      {title ? <span className="text-gray-600 text-sm mb-1">{title}</span> : null}
      <div className={`w-full bg-gray-200 h-3 rounded-lg overflow-hidden ${percentage > 120 ? "animate-bounce" : ""}`}>
        <div className="bg-[#f00] rounded-lg" style={{ width: `${Math.min(100, percentage)}%` }}>
          <div className="flex justify-center items-center h-3 text-sm font-medium bg-blue-dark rounded-lg" />
        </div>
      </div>
      <div className="flex items-start justify-center text-sm pt-1">
        <div className="px-2">{percentage.toFixed(2)}%</div>
      </div>
    </div>
  );
};
