import React, { Fragment, useEffect, useState } from "react";
import { Combobox as HLCombobox, Listbox, Transition } from "@headlessui/react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Combobox({ options, onChange, selected, disabled = false, placeholder = "Sélectionner une option" }) {
  const [selectedOption, setSelectedOption] = useState(options.find((option) => option.value === selected));
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === selected));
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filteredOptions);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <HLCombobox value={selectedOption} onChange={handleSelect} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            <div className="relative w-full bg-white py-3 px-4 rounded flex items-center justify-between border border-[#798EA2] text-left focus:outline-none focus-visible:ring-none">
              <HLCombobox.Input className="w-full text-left focus:outline-none focus:ring-none" onChange={handleInputChange} placeholder={placeholder} />
              <HLCombobox.Button>
                {({ open }) =>
                  open ? <BsChevronUp className="h-4 w-4 text-gray-900" aria-hidden="true" /> : <BsChevronDown className="h-4 w-4 text-gray-900" aria-hidden="true" />
                }
              </HLCombobox.Button>
            </div>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <HLCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredOptions.map((option, i) => (
                  <HLCombobox.Option
                    key={i}
                    className={({ active }) =>
                      classNames(active ? "text-secoset-green-700 bg-secoset-green-5" : "text-gray-900", "relative cursor-default select-none py-2 pl-3 pr-9 list-none")
                    }
                    value={option}>
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate text-sm")}>{option.label}</span>
                        {selected && (
                          <span className={classNames(active ? "text-secoset-green-700" : "text-secoset-green-700", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                            <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </HLCombobox.Option>
                ))}
              </HLCombobox.Options>
            </Transition>
          </div>
        </>
      )}
    </HLCombobox>
  );
}
