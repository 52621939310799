import React, { useState } from "react";
import validator from "validator";
import { Formik, Form } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import * as Sentry from "@sentry/react";

import api from "../../services/api";
import useStore from "../../services/zustand";

const Signin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setUser } = useStore();

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    if (validator.isEmpty(values.password)) errors.password = "Ce champ est requis";
    setErrors(errors);
    return !errors.email && !errors.password;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .post("/auth/signin", values)
      .then((res) => {
        const user = res.user;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        setUser(user);
      })
      .catch((error) => {
        console.log("error", error);
        setErrors({ password: "Email ou mot de passe incorrect" });
      });
  };

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center">
      <div className="w-1/4  items-center justify-center">
        <h3 className="font-bold text-4xl text-cyan-800 leading-13 text-center">Connexion au Back Office</h3>
        <div className="flex items-center justify-center">
          <div className="flex-1">
            <div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={handleSubmit}>
                {({ values, errors, handleChange }) => (
                  <Form className="my-4">
                    <div className="w-[360px] mb-4">
                      <label className="font-bold text-gray-500" htmlFor="email">
                        Email
                      </label>
                      <input className="mt-2 input" placeholder="john@ugc.fr" name="email" type="email" value={values.email} onChange={handleChange} />
                      {errors.email && <div className="text-sm text-red">{errors.email}</div>}
                    </div>
                    <div className="w-[360px]">
                      <label className="font-bold text-gray-500 mt-6" htmlFor="password">
                        Mot de passe
                      </label>
                      <div className="mt-2 flex flex-row gap-3 items-center">
                        <input
                          className="input flex-1"
                          placeholder="Votre mot de passe"
                          name="password"
                          type={passwordVisible ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                        />
                        {passwordVisible ? (
                          <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                        ) : (
                          <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                        )}
                      </div>
                      {errors.password && <div className="mt-1 text-sm text-red">{errors.password}</div>}
                    </div>

                    <button className="blue-button w-full mt-10" type="submit">
                      Connexion
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signin;
