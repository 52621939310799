import React from "react";

const Demos = () => {
  return (
    <>
      <p>Demos Secoset</p>
    </>
  );
};

export default Demos;
