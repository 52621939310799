import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";

import api from "../../services/api";
import { TablePaginator } from "../../components/table";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Select from "components/select";
import { SECOSET_APP_URL } from "../../config";
import { Modal } from "components/modal";

import { toast } from "react-toastify";

const roleLabels = {
  owner: "Propriétaire",
  admin: "Administrateur",
  user: "Utilisateur",
};

export const UserList = () => {
  const [searchParams] = useSearchParams({});
  const [organizations, setOrganizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [loginAsToken, setLoginAsToken] = useState();
  const [page, setPage] = useState(1);
  const newFilterValues = { role: "user" };
  if (searchParams.get("organization")) newFilterValues.organization = searchParams.get("organization");
  const [filterValues, setFilterValues] = useState(newFilterValues);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    api
      .get("/organizations/all")
      .then((res) => {
        if (res.ok) {
          setOrganizations(res.data);
        }
      })
      .catch((err) => console.error(err));

    api
      .get("/secoset-user/loginas")
      .then((res) => setLoginAsToken(res.token))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    api
      .get(`/secoset-user/all${buildQuery()}`)
      .then((res) => {
        if (res.ok) {
          setUsers(res.data);
          setDisplayedUsers(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, [filterValues]);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search) {
      setDisplayedUsers(
        users.filter(
          (u) =>
            (u.firstName && u.firstName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            (u.lastName && u.lastName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            u.email.toLowerCase().search(search.toLowerCase()) !== -1,
        ),
      );
    } else setDisplayedUsers(users);

    setPage(1);
  };

  const buildQuery = () => {
    const query = Object.keys(filterValues)
      .filter((key) => filterValues[key])
      .map((key) => `${key}=${filterValues[key]}`)
      .join("&");
    return `${query ? `?${query}` : ""}`;
  };

  if (!users || !loginAsToken) return <div>Chargement...</div>;

  return (
    <div className="bg-white rounded p-10">
      <CreateModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} />
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800">Secoset - Utilisateurs</h1>
        <div className="flex items-center gap-4">
          <div className="flex w-full flex-wrap items-center relative">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
          <button className="blue-button whitespace-nowrap" onClick={() => setIsCreateModalOpen(true)}>
            Créer un utilisateur
          </button>
        </div>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
          {displayedUsers.length > 1 ? displayedUsers.length + " utilisateurs" : displayedUsers.length + " utilisateur"}
        </span>
        <Select
          options={organizations.map((e) => ({ label: e.name, value: e._id.toString() }))}
          value={filterValues.organization}
          placeholder="Société"
          onChange={(value) => {
            if (filterValues.organization === value) setFilterValues({ ...filterValues, organization: null });
            else setFilterValues({ ...filterValues, organization: value });
          }}
        />
        <Select
          options={[
            { label: "Oui", value: true },
            { label: "Non", value: false },
          ]}
          value={filterValues.hasPayed}
          placeholder="Premium"
          onChange={(value) => {
            if (filterValues.hasPayed === value) setFilterValues({ ...filterValues, hasPayed: null });
            else setFilterValues({ ...filterValues, hasPayed: value });
          }}
        />
      </div>
      <TablePaginator
        data={displayedUsers.slice((page - 1) * 10, page * 10)}
        length={displayedUsers.length}
        page={page}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[35%]">Utilisateurs</div>
            <div className="px-6 w-[10%]">Accès premium</div>
            <div className="px-6 w-[25%]">Société</div>
            <div className="px-6 w-[15%]">Date de création</div>
            <div className="px-6 w-[15%]">Se connecter</div>
          </>
        )}
        renderItem={(item) => <Line item={item} loginAsToken={loginAsToken} organizations={organizations} />}
      />
    </div>
  );
};

const Line = ({ item, loginAsToken, organizations }) => {
  const [userOrganizations, setUserOrganizations] = useState([]);

  useEffect(() => {
    setUserOrganizations(organizations.filter((e) => item.organizationIds.includes(e._id.toString())));
  }, [organizations, item.organizationIds]);

  return (
    <>
      <div className="px-6 w-[35%]">
        <Link to={`/users-secoset/${item._id.toString()}`} className="flex items-center">
          <div className="rounded-full bg-secoset-green-5 h-10 shrink-0 w-10 justify-center flex items-center text-xs text-secoset-green-900">
            <span className="font-bold">
              {item.firstName && item.lastName
                ? `${item.firstName?.charAt(0).toLocaleUpperCase()} ${item.lastName?.charAt(0).toLocaleUpperCase()}`
                : item.firstName
                ? `${item.firstName?.charAt(0).toLocaleUpperCase()}`
                : item.lastName
                ? `${item.lastName?.charAt(0).toLocaleUpperCase()}`
                : "/"}
            </span>
          </div>
          <div className="ml-8 flex flex-col">
            <span className="font-semibold">{`${item.firstName || ""} ${item.lastName || ""}`}</span>
            <span>{item.email}</span>
          </div>
        </Link>
      </div>
      <div className="px-6 w-[10%] line-clamp-2">
        {item?.hasPayed ? <div className="text-green-700 bg-green-100 px-2 w-fit">Oui</div> : <div className="text-gray-700 bg-gray-100 px-2 w-fit">Non</div>}
      </div>
      <div className="px-6 w-[25%] line-clamp-2">
        {userOrganizations?.length === 0 ? (
          <div className="italic text-gray-600">Aucune</div>
        ) : (
          userOrganizations?.map((organization) => <div className="bg-secoset-green-5 mr-1 px-2 w-fit">{organization.name}</div>)
        )}
      </div>
      <div className="px-6 w-[15%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
      <div className="px-6 w-[15%]">
        <a className="text-sky-400 font-bold" href={`${SECOSET_APP_URL}/loginas?id=${item._id.toString()}&token=${loginAsToken}`} target="_blank">
          Login As
        </a>
      </div>
    </>
  );
};

const CreateModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { ok, data } = await api.post("/secoset-user", { email });
      if (ok) {
        navigate(`/users-secoset/${data._id.toString()}`);
        toast.success("Utilisateur créé");
      }
    } catch (err) {
      toast.error("Erreur lors de la création de l'utilisateur");
      console.log(err);
    }
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
      <div className="p-8">
        <h1 className="text-2xl text-center font-semibold">Créer un utilisateur</h1>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="name">
              Email
            </label>
            <input required type="email" className="input" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="flex justify-end">
            <button type="submit" className="blue-button mt-10 w-56" disabled={!email}>
              Creer
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
