import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { RiErrorWarningFill, RiCloseCircleLine } from "react-icons/ri";

import api from "../../services/api";
import Select from "components/select";

export const EditOrganization = () => {
  const { id } = useParams();
  const [organization, setOrganization] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/organizations/${id}`).then((res) => {
      if (res.ok) setOrganization(res.data);
    });
  }, [id]);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.name || "")) errors.name = "Le nom est requis";
    if (validator.isEmpty(values.director || "")) errors.director = "Le nom est requis";

    setErrors(errors);
    return !errors.name || !errors.director;
  };

  const handleSubmit = (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .put(`/organizations/${id}`, values)
      .then(() => {
        setOrganization(values);
        toast.success("L'organization a bien été modifiée");
      })
      .catch((err) => {
        console.log(`Erreur ${err}`);
        toast.error("Une erreur est survenue");
      });
  };

  const handleDelete = () => {
    // use window.confirm to get a native browser confirm popup
    if (!window.confirm("Êtes-vous sûr(e) de vouloir supprimer cette organisation ? (la organisation sera encore en base de donnée mais elle n'apparaitera plus)")) return;

    api.delete(`/organizations/${id}`).then((res) => {
      if (!res.ok) toast.error("Une erreur est survenue");
      toast.success("Production supprimée");
      navigate("/organizations");
    });
  };

  if (!organization) return <h1>Chargement...</h1>;

  return (
    <div className="bg-white rounded p-10">
      <h1 className="font-bold text-4xl text-cyan-800 mb-10">Secoset - Société - {organization.name}</h1>
      <div className="flex justify-center items-start gap-8">
        <Formik initialValues={organization} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="flex-1 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="name">
                  Nom
                </label>
                <input className="input" name="name" value={values.name} onChange={handleChange} />
                {errors.name && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="director">
                  Propriétaire
                </label>
                <input className="input" name="director" value={values.director} onChange={handleChange} />
                {errors.director && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.director}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="plan">
                  Plan
                </label>
                <Select
                  options={[
                    {
                      value: "demo",
                      label: "Découverte",
                    },
                    {
                      value: "supported",
                      label: "En accompagnement",
                    },
                  ]}
                  value={values.plan}
                  onChange={(value) => setFieldValue("plan", value)}
                />

                {errors.plan && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.plan}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <p className="mb-2 font-semibold text-slate-500 text-sm">Accès</p>
                <div className="flex items-center gap-4 mb-1">
                  <input type="checkbox" name="accessDashboard" checked={values.accessDashboard} value={values.accessDashboard} onChange={handleChange} />
                  <label className="font-semibold text-slate-500 text-sm" htmlFor="accessDashboard">
                    Accès au dashboard
                  </label>
                  {errors.accessDashboard && (
                    <div className="flex items-center text-sm text-red-900">
                      <RiErrorWarningFill className="mr-2" />
                      {errors.accessDashboard}
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-4">
                  <input type="checkbox" name="accessCriteria" checked={values.accessCriteria} value={values.accessCriteria} onChange={handleChange} />
                  <label className="font-semibold text-slate-500 text-sm" htmlFor="accessCriteria">
                    Accès à la création de projets
                  </label>
                  {errors.accessCriteria && (
                    <div className="flex items-center text-sm text-red-900">
                      <RiErrorWarningFill className="mr-2" />
                      {errors.accessCriteria}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-2 flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="organizations">
                  Utilisateur(s)
                </label>
                <Users organization={values} setFieldValue={setFieldValue} />
              </div>
              <div className="col-span-2 flex justify-start gap-4 mt-4">
                <Link to="/organizations" className="empty-button">
                  Retour
                </Link>
                <button type="submit" className="blue-button">
                  Sauvegarder
                </button>
                <button type="button" onClick={handleDelete} className="bg-red-500 text-white px-4 font-medium">
                  Supprimer
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="bg-secoset-green-5 p-5 mt-8 flex flex-col gap-3">
          <div className="flex items-center">
            <p className="font-bold">identifiant :</p>
            <p className="ml-2">{organization._id}</p>
          </div>
          <div className="flex items-center">
            <p className="font-bold">Créé le :</p>
            <p className="ml-2">{new Date(organization.createdAt).toLocaleDateString("fr")}</p>
          </div>
          <Link to={`/productions?organization=${organization._id.toString()}`} className="text-sky-400 font-bold">
            Voir les projets
          </Link>
          <Link to={`/users-secoset?organization=${organization._id.toString()}`} className="text-sky-400 font-bold">
            Voir les utilisateurs
          </Link>
        </div>
      </div>
    </div>
  );
};

const Users = ({ organization, setFieldValue }) => {
  const [users, setUsers] = useState();
  const [organizationUsers, setOrganizationUsers] = useState();

  const get = async () => {
    try {
      const { ok, data } = await api.get(`/secoset-user/all`);
      setUsers(data);
    } catch (e) {}
  };

  const removeUser = async (user) => {
    try {
      const { ok, data } = await api.put(`/secoset-user/${user._id.toString()}`, { organizationIds: user.organizationIds.filter((oId) => oId !== organization._id.toString()) });
      const newUsers = (organization.users || []).filter((u) => u !== user._id.toString());
      setFieldValue("users", newUsers);
      api
        .put(`/organizations/${organization._id}`, { users: newUsers })
        .then(() => {
          toast.success("L'organization a bien été modifiée");
        })
        .catch((err) => {
          console.log(`Erreur ${err}`);
          toast.error("Une erreur est survenue");
        });
    } catch (e) {
      console.log("e", e);
    }
  };

  const addUser = async (userId) => {
    try {
      const user = users.find((u) => u._id.toString() === userId);
      const id = organization._id.toString();
      let _organizationIds = user.organizationIds || [];
      if (!_organizationIds.includes(id)) _organizationIds.push(id);

      const { ok, data } = await api.put(`/secoset-user/${user._id.toString()}`, { organizationIds: _organizationIds });
      const newUsers = [...(organization.users || []), userId];
      setFieldValue("users", newUsers);
      api
        .put(`/organizations/${organization._id}`, { users: newUsers })
        .then(() => {
          toast.success("L'organization a bien été modifiée");
        })
        .catch((err) => {
          console.log(`Erreur ${err}`);
          toast.error("Une erreur est survenue");
        });
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    get();
  }, [organization]);

  useEffect(() => {
    if (!users) return;
    const _organizationUsers = users.filter((u) => (organization.users || []).find((uId) => uId === u._id.toString()));
    setOrganizationUsers(_organizationUsers);
  }, [users]);

  if (!users) return <div>Chargement...</div>;

  return (
    <div className="w-full">
      {(organizationUsers || []).map((user) => (
        <div key={user._id} className="bg-secoset-green-5 rounded p-2 text-sm mb-2 flex items-center gap-2">
          <div className="flex-1 pr-6">
            <Link to={`/users-secoset/${user._id.toString()}`} className="flex items-center">
              <div className="flex flex-col">
                <span className="font-semibold">{`${user.firstName || ""} ${user.lastName || ""}`}</span>
                <span>{user.email}</span>
              </div>
            </Link>
          </div>
          <div className="p-2 cursor-pointer" onClick={() => removeUser(user)}>
            <RiCloseCircleLine />
          </div>
        </div>
      ))}
      <Select
        placeholder="Ajouter un utilisateur"
        options={users
          .sort((a, b) => {
            if (a.firstName < b.firstName) return -1;
            if (a.firstName > b.firstName) return 1;
            return 0;
          })
          .map((user) => ({ value: user._id, label: `${user.firstName} ${user.lastName} (${user.email})` }))}
        value={""}
        onChange={(userId) => addUser(userId)}
        name="users"
      />
    </div>
  );
};
