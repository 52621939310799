import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import Select from "components/select";
import { RiErrorWarningFill, RiCloseCircleLine } from "react-icons/ri";

import api from "../../services/api";
import { SECOSET_APP_URL } from "../../config";

export const EditUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loginAsToken, setLoginAsToken] = useState();

  useEffect(() => {
    api.get(`/secoset-user/${id}`).then((res) => {
      if (res.ok) setUser(res.data);
    });
    api.get("/secoset-user/loginas").then((res) => {
      setLoginAsToken(res.token);
    });
  }, [id]);

  const validate = (values, setErrors) => {
    const errors = {};
    if (values.status === "WAITING_VERIFICATION" && values.emailVerified) {
      errors.status = "Un utilisateur en attente de vérification ne peut pas avoir son email vérifié";
      errors.emailVerified = "Un utilisateur en attente de vérification ne peut pas avoir son email vérifié";
    }
    if (values.status !== "WAITING_VERIFICATION" && !values.emailVerified) {
      errors.status = "Un utilisateur n'ayant pas son email vérifié doit être en attente de vérification";
      errors.emailVerified = "Un utilisateur n'ayant pas son email vérifié doit être en attente de vérification";
    }

    if (validator.isEmpty(values.firstName || "")) errors.firstName = "Le nom est requis";
    if (validator.isEmpty(values.email || "")) errors.email = "L'email est requis";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .put(`/secoset-user/${id}`, values)
      .then((res) => {
        if(!res.ok) {
          toast.error(res.message);
          return;
        }
        setUser(res.data);
        toast.success("L'utilisateur a bien été modifié");
      })
      .catch((err) => {
        console.log(`Erreur ${err}`);
        toast.error("Une erreur est survenue");
      });
  };

  if (!user) {
    return <h1>Chargement...</h1>;
  }

  return (
    <div className="bg-white rounded p-10">
      <h1 className="font-bold text-4xl text-cyan-800 mb-10">Secoset - Utilisateurs - {user.firstName}</h1>
      <div className="flex justify-center items-start gap-8">
        <Formik initialValues={{ ...user }} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="flex-1 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="firstName">
                  Prénom
                </label>
                <input className="input" name="firstName" value={values.firstName} onChange={handleChange} />
                {errors.firstName && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.firstName}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="lastName">
                  Nom de famille
                </label>
                <input className="input" name="lastName" value={values.lastName} onChange={handleChange} />
                {errors.lastname && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.lastname}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="email">
                  E-mail
                </label>
                <input className="input" name="email" value={values.email} onChange={handleChange} />
                {errors.email && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="status">
                  Statut
                </label>

                <Select
                  options={[
                    { label: "En attente de validation", value: "WAITING_VERIFICATION" },
                    { label: "En attente d'information", value: "WAITING_INFORMATION" },
                    { label: "Validé", value: "VALIDATED" },
                    { label: "En attente d'acceptation", value: "WAITING_ACCEPTATION" },
                  ]}
                  value={values.status}
                  onChange={(value) => setFieldValue("status", value)}
                />

                {errors.status && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.status}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="status">
                  Email vérifié
                </label>
                <Select
                  options={[
                    { label: "Oui", value: true },
                    { label: "Non", value: false },
                  ]}
                  value={values.emailVerified}
                  onChange={(value) => setFieldValue("emailVerified", value)}
                />

                {errors.emailVerified && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.emailVerified}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="status">
                  Accès premium
                </label>
                <Select
                  options={[
                    { label: "Oui", value: true },
                    { label: "Non", value: false },
                  ]}
                  value={values.hasPayed}
                  onChange={(value) => setFieldValue("hasPayed", value)}
                />

                {errors.hasPayed && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.hasPayed}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="status">
                  Rôle
                </label>
                <Select options={["user", "admin", "eco-manager"].map((r) => ({ label: r, value: r }))} value={values.role} onChange={(value) => setFieldValue("role", value)} />
                {errors.role && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.role}
                  </div>
                )}
              </div>
              <div className="col-span-2 flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="organizations">
                  Société(s)
                </label>
                <Organizations user={values} setFieldValue={setFieldValue} />
                {errors.role && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.role}
                  </div>
                )}
              </div>

              <div className="col-span-2 flex justify-start gap-4 mt-4">
                <Link to="/users-secoset" className="rounded w-48 p-2 text-center text-slate-700 border border-slate-700 hover:bg-slate-50 hover:font-semibold">
                  Retour
                </Link>
                <button type="submit" className="rounded w-48 p-2 bg-cyan-900 text-white hover:bg-white hover:text-cyan-900 hover:border hover:border-cyan-900">
                  Sauvegarder
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="bg-sky-100 p-5 mt-8 flex flex-col gap-3">
          <div className="flex items-center">
            <p className="font-bold">identifiant :</p>
            <p className="ml-2">{user._id}</p>
          </div>
          <div className="flex items-center">
            <p className="font-bold">Inscrit le :</p>
            <p className="ml-2">{new Date(user.createdAt).toLocaleDateString("fr")}</p>
          </div>
          <div className="flex items-center">
            <p className="font-bold">Activé le :</p>
            <p className="ml-2"> {user.activatedAt ? new Date(user.activatedAt).toLocaleDateString("fr") : "Utilisateur pas encore validé"}</p>
          </div>
          <a className="text-sky-400 font-bold" href={`${SECOSET_APP_URL}/loginas?id=${user._id.toString()}&token=${loginAsToken}`} target="_blank">
            Login As
          </a>
        </div>
      </div>
    </div>
  );
};

const Organizations = ({ user }) => {
  const [organizations, setOrganizations] = useState();
  const [userOrganizations, setUserOrganizations] = useState();

  const get = async () => {
    try {
      const { ok, data } = await api.get(`/organizations/all`);
      setOrganizations(data);
    } catch (e) {}
  };

  useEffect(() => {
    get();
  }, [user]);

  useEffect(() => {
    if (!organizations) return;
    const _userOrganizations = organizations.filter((o) => (user.organizationIds || []).find((uo) => uo === o._id.toString()));
    setUserOrganizations(_userOrganizations);
  }, [organizations]);

  if (!organizations) return <div>Chargement...</div>;
  if (userOrganizations?.length === 0) return <div className="text-gray-400">Aucune société</div>;

  return (
    <div>
      {(userOrganizations || []).map((organization) => (
        <div key={organization._id} className="bg-secoset-green-5 rounded pl-2 text-sm mb-2 flex items-center gap-2">
          <Link to={`/organizations/${organization._id.toString()}`} className="p-2 flex items-center">
            <div className="flex-1 pr-6">{organization.name}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};
