import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import toast from "react-hot-toast";
import { RiErrorWarningFill, RiCloseCircleLine } from "react-icons/ri";
import { IoCopyOutline } from "react-icons/io5";
import Select from "components/select";

import SelectMonth from "components/selectMonth";

import api from "../../../services/api";
import useStore from "../../../services/zustand";
import { SECOSET_APP_URL } from "../../../config";
import { getDaysInMonth, copyToClipboard } from "utils";
import Combobox from "components/Combobox";
import { PRODUCTION_PLAN, PRODUCTION_PLAN_COLORS } from "../../../utils/constants";

export default function EditProduction() {
  const [production, setProduction] = useState(null);
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const { user } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/productions/${id}`).then((res) => {
      if (res.ok) setProduction(res.data);
    });
    api.get("/secoset-user/all").then((res) => {
      setUsers(res.data);
    });
    api.get("/organizations/all").then((res) => {
      if (res.ok) setOrganizations(res.data);
    });
  }, [id]);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.title || "")) errors.title = "Le nom est requis";

    setErrors(errors);
    return !errors.title;
  };

  const handleSubmit = (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    api
      .put(`/productions/${id}`, values)
      .then((res) => {
        setProduction(res.data);
        toast.success("La production a bien été modifiée");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Une erreur est survenue");
      });
  };

  const handleDelete = () => {
    // use window.confirm to get a native browser confirm popup
    if (!window.confirm("Êtes-vous sûr(e) de vouloir supprimer cette production ? (la production sera encore en base de donnée mais elle n'apparaitera plus)")) return;

    api.delete(`/productions/${id}`).then((res) => {
      if (!res.ok) toast.error("Une erreur est survenue");
      toast.success("Production supprimée");
      navigate("/productions");
    });
  };

  if (!production || !organizations || !users) return <h1>Chargement...</h1>;

  return (
    <div className="bg-white rounded p-10">
      <Formik initialValues={{ ...production, organizations: production.organizations.map((o) => o._id) }} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, setFieldValue }) => (
          <div className="flex justify-center items-start gap-8">
            <Form className="flex-1 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="title">
                  Nom
                </label>
                <input className="input" name="title" value={values.title} onChange={handleChange} />
                {errors.title && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.title}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="plan">
                  Plan
                </label>
                <Select
                  options={Object.entries(PRODUCTION_PLAN).map(([key, value]) => ({ label: value, value: key }))}
                  value={values.plan}
                  onChange={(value) => setFieldValue("plan", value)}
                />

                {errors.plan && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.plan}
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="status">
                  Etat du projet
                </label>
                <Select
                  options={[
                    {
                      value: "error",
                      label: "En erreur",
                    },
                    {
                      value: "rejected",
                      label: "Rejeté",
                    },
                    {
                      value: "ongoing",
                      label: "En cours",
                    },
                    {
                      value: "pending",
                      label: "En attente de label",
                    },
                    {
                      value: "validated",
                      label: "Labelisé",
                    },
                  ]}
                  value={values.status}
                  onChange={(value) => setFieldValue("status", value)}
                />

                {errors.status && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.status}
                  </div>
                )}
              </div>

              <div className="flex flex-col col-span-2">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="eco-manager">
                  Eco-manageur
                </label>
                <Select
                  placeholder="Aucun"
                  options={users
                    .filter((e) => e.role === "eco-manager")
                    .map((user) => ({ value: user._id.toString(), label: `${user.firstName} ${user.lastName} - ${user.email}` }))}
                  value={values.ecoManager}
                  onChange={(value) => setFieldValue("ecoManager", value)}
                />

                {errors.ecoManager && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.ecoManager}
                  </div>
                )}
              </div>

              <div className="col-span-2 flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="organizations">
                  Société
                </label>
                <div className="flex items-center flex-wrap gap-x-2">
                  {values.organizations.length ? (
                    values.organizations.map((id, i) => {
                      const organization = organizations.find((o) => o._id.toString() === id);
                      if (!organization) return null;
                      return (
                        <div key={i} className="bg-secoset-green-5 rounded pl-2 text-sm mb-2 flex flex-row items-center">
                          <div>{organization.name}</div>
                          <div
                            className="p-2 ml-2 cursor-pointer"
                            onClick={() =>
                              setFieldValue(
                                "organizations",
                                values.organizations.filter((o) => o !== id),
                              )
                            }>
                            <RiCloseCircleLine className="ml-2" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="mb-2 italic text-gray-500">Non renseignée</span>
                  )}
                </div>

                <Combobox
                  placeholder="Selectionner une société"
                  options={organizations.map((o) => ({ value: o._id.toString(), label: o.name }))}
                  onChange={(item) => (!values.organizations.includes(item.value) ? setFieldValue("organizations", [...values.organizations, item.value]) : null)}
                  name="organizations"
                />
                {errors.role && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.role}
                  </div>
                )}
              </div>

              <div className="col-span-2 flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="users">
                  Personnes ayant accès à la production
                </label>
                <div
                  className="flex items-center flex-wrap gap-x-2
                ">
                  {values.users.length ? (
                    values.users.map((id, i) => {
                      const user = users.find((u) => u._id.toString() === id);
                      if (!user) return null;
                      return (
                        <div key={i} className="bg-secoset-green-5 rounded pl-2 text-sm mb-2 flex flex-row items-center">
                          <div>
                            {user.firstName} {user.lastName} - {user.email.toLowerCase()}
                          </div>
                          <div
                            className="p-2 ml-2 cursor-pointer"
                            onClick={() => {
                              const newUsers = values.users.filter((u) => u !== id);
                              setFieldValue("users", newUsers);
                            }}>
                            <RiCloseCircleLine className="ml-2" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="mb-2">Aucun personne n'a accès à la production</span>
                  )}
                </div>
                <Combobox
                  placeholder="Selectionner un utilisateur"
                  options={users.map((user) => ({ value: user._id.toString(), label: `${user.firstName} ${user.lastName} - ${user.email}` }))}
                  onChange={(item) => (!values.users.includes(item.value) ? setFieldValue("users", [...values.users, item.value]) : null)}
                  name="users"
                />
                {errors.users && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.users}
                  </div>
                )}
              </div>

              <div className="col-span-2 flex flex-col">
                <label className="mb-2 font-semibold text-slate-500 text-sm" htmlFor="users">
                  Personnes notifiées
                </label>
                <div
                  className="flex items-center flex-wrap gap-x-2
                ">
                  {values.usersToNotify.length ? (
                    values.usersToNotify.map((id, i) => {
                      const user = users.find((u) => u._id.toString() === id);
                      if (!user) return null;
                      return (
                        <div key={i} className="bg-secoset-green-5 rounded pl-2 text-sm mb-2 flex flex-row items-center">
                          <div>
                            {user.firstName} {user.lastName} - {user.email.toLowerCase()}
                          </div>
                          <div
                            className="p-2 ml-2 cursor-pointer"
                            onClick={() => {
                              const newUsersToNotify = values.usersToNotify.filter((u) => u !== id);
                              setFieldValue("usersToNotify", newUsersToNotify);
                            }}>
                            <RiCloseCircleLine className="ml-2" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="mb-2">Aucun personne n'est notifiée</span>
                  )}
                </div>

                <Combobox
                  placeholder="Selectionner un utilisateur"
                  options={users.map((user) => ({ value: user._id.toString(), label: `${user.firstName} ${user.lastName} - ${user.email}` }))}
                  onChange={(item) => (!values.usersToNotify.includes(item.value) ? setFieldValue("usersToNotify", [...values.usersToNotify, item.value]) : null)}
                  name="usersToNotify"
                />
                {errors.usersToNotify && (
                  <div className="flex items-center text-sm text-red-900">
                    <RiErrorWarningFill className="mr-2" />
                    {errors.usersToNotify}
                  </div>
                )}
              </div>

              <div className="col-span-2 flex justify-start gap-4 mt-4">
                <Link to="/productions" className="empty-button">
                  Retour
                </Link>
                <button type="submit" className="blue-button">
                  Sauvegarder
                </button>
                <button type="button" onClick={handleDelete} className="bg-red-500 text-white px-4 font-medium">
                  Supprimer
                </button>
              </div>
            </Form>
            <div>
              <div className="bg-secoset-green-5 p-5 mt-8 mb-4 flex flex-col gap-3">
                <div className="flex items-center">
                  <p className="font-bold">Société :</p>
                  <p className="text-sky-400 font-bold ml-2">{production.organizations.length ? production.organizations.map((o) => o.name).join(", ") : "Non renseignée"}</p>
                </div>
                <div className="flex items-center">
                  <p className="font-bold">identifiant :</p>
                  <p className="ml-2">{production._id}</p>
                </div>
                <div className="flex items-center">
                  <p className="font-bold">Créé le :</p>
                  <p className="ml-2">{new Date(production.createdAt).toLocaleDateString("fr")}</p>
                </div>
                <div className="flex items-center">
                  <p className="font-bold">Créé par :</p>
                  {console.log(production.owner)}
                  {production.owner && (
                    <Link
                      className="text-sky-400 font-bold ml-2"
                      to={`/users-secoset/${production.owner._id.toString()}`}>{`${production.owner.firstName} ${production.owner.lastName}`}</Link>
                  )}
                </div>
                <a className="text-sky-400 font-bold" href={`${SECOSET_APP_URL}/saas/productions/${production._id.toString()}`} target="_blank">
                  Voir le projet sur Secoset
                </a>
              </div>
              <Calculations budget={values.budget} production={production} />
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="budget">
                  Devis
                </label>
                <input className="input" type="number" name="budget" value={values.budget} onChange={handleChange} />
                {errors.budget && <div className="flex items-center text-sm text-red-main">{errors.budget}</div>}
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

const Calculations = ({ budget, production }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?projectTitle=${encodeURIComponent(production.title)}`);
      setActivities(data);
    })();
  }, []);

  if (!activities) return <div className="p-4 text-sm">Chargement...</div>;

  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);
  const profit = budget - cost;

  const displayProfit = () => {
    if (profit < 0) return <div className="font-bold text-secoset-error-700 text-2xl">{`${profit}€`}</div>;
    return <div className="font-bold text-secoset-green-validation text-2xl">{`+${profit}€`}</div>;
  };

  return (
    <>
      <div className="mb-2">
        <div className="font-bold text-gray-500">Rentabilité</div>
        {displayProfit()}
      </div>
      <div className="mb-2">
        <div className="font-bold text-gray-500">Budget total consommé</div>
        <div className="font-bold text-secoset-green-900 text-2xl">{cost}€</div>
      </div>
    </>
  );
};
