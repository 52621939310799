import React from "react";
import { Route, Routes } from "react-router-dom";

import { ProductionList } from "./list";

export default () => {
  return (
    <Routes>
      <Route index element={<ProductionList />} />
    </Routes>
  );
};
