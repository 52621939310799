import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { Toaster } from "react-hot-toast";

import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import { SENTRY_DSN, ENVIRONMENT } from "./config";

Sentry.init({
  enabled: ENVIRONMENT !== "development",
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: "admin",
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Toaster />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
