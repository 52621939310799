import React from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";

import All from "./all";
import User from "./user";

const Index = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const listTab = ["all", "user"];
    if (!listTab.includes(tab)) navigate(`/activity/user`);
  }, [tab]);

  return (
    <>
      <nav className="flex flex-1">
        <TabItem title="All" to={`/activity/all`} />
        <TabItem title="User" to={`/activity/user`} />
      </nav>
      <div className="bg-white pt-4">
        {tab === "all" && <All />}
        {tab === "user" && <User />}
      </div>
    </>
  );
};

const TabItem = ({ to, title }) => (
  <NavLink
    to={to}
    activeClassName="text-black bg-white border-none"
    className="text-[13px] px-3 py-2 cursor-pointer text-gray-600 bg-gray-50 border-t-[1px] border-r-[1px] border-l-[1px] border-gray-200 hover:text-black">
    <div className="flex items-center gap-2">{title}</div>
  </NavLink>
);

export default Index;
