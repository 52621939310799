import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import SelectMonth from "../../components/selectMonth";
// import LoadingButton from "../../components/loadingButton";

import api from "../../services/api";

const Activity = () => {
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [exporting, setExporting] = useState(false);
  const [activities, setActivities] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!date) return;
    load();
  }, [date]);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const date = params.get("date");
    if (date) setDate(new Date(date));
  }, []);

  async function load() {
    setLoading(true);
    const { data } = await api.get(`/activity?date=${date?.getTime()}`);
    setLoading(false);

    let arr = [];

    for (let i = 0; i < data.length; i++) {
      const found = arr.find((e) => e.userId === data[i].userId);
      if (!found) {
        const obj = { userId: data[i].userId, detail: data[i].detail.map((e) => e.value), userName: data[i].userName, total: data[i].total };
        arr.push(obj);
      } else {
        found.total += data[i].total;
        for (let j = 0; j < data[i].detail.length; j++) {
          found.detail[j] += data[i].detail[j].value;
        }
      }
    }

    arr.sort((a, b) => a.total - b.total);
    setActivities(arr);
  }

  // const handleExport = async () => {
  //   setExporting(true);
  //   const arr = [];
  //   for (let activity of activities) {
  //     const obj = { ...activity };

  //     const { data: user } = await api.get(`/user/${activity.userId}`);
  //     obj.job_title = user.job_title;
  //     if (user.entry_date) obj.entry_date = new Date(user.entry_date).toISOString().slice(0, 10);

  //     obj.daysWorked = activity.total / 8;
  //     arr.push(obj);
  //   }
  //   await exportData(arr);
  //   setExporting(false);
  // };

  const days = getDaysInMonth(date?.getMonth(), date?.getFullYear());
  const getTotal = () => (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);

  return (
    <div className="w-screen md:w-full">
      <div className="flex items-center space-x-4 px-4">
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
        {/* <LoadingButton loading={exporting} className="btn btn-primary self-start" onClick={handleExport}>
          Export
        </LoadingButton> */}
      </div>

      <div className="flex flex-wrap py-3 gap-4 text-black">
        <div className="w-screen md:w-full p-2">
          {loading ? (
            <div>loading...</div>
          ) : (
            <div className="rounded-xl bg-[#fff]">
              <div className=" max-h-[calc(100vh-240px)] overflow-scroll relative">
                <div className="fixed z-99 px-2 w-[150px] h-[40px] bg-white  py-[10px] text-[14px] font-bold text-[#212325] text-left pl-[10px] " style={{ zIndex: 99 }}>
                  Users
                </div>
                <table className="w-full overflow-scroll">
                  <thead className="sticky top-0">
                    <tr>
                      <th>Users</th>
                      {days.map((e) => {
                        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                        const _date = new Date(e);
                        const day = _date.getDay();
                        const weekday = days[day];
                        const date = _date.getDate();
                        return (
                          <th
                            className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                            key={e}
                            day={day}>
                            <div>{weekday}</div>
                            <div>{date}</div>
                          </th>
                        );
                      })}
                      {/* <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-[white]`} /> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t border-b border-r border-[#E5EAEF]">
                      <th className="px-2 sticky left-0 bg-white">
                        <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                          <div>{`${getTotal()} days`}</div>
                        </div>
                      </th>
                    </tr>
                    {activities.map((e, i) => {
                      return (
                        <React.Fragment key={e.projectId}>
                          <tr key={`${e.userName}-${i}`} className="border-t border-b border-r border-[#E5EAEF]">
                            <th className=" border-t border-b border-r text-[12px] font-bold text-[#212325] text-left sticky left-0 bg-white">
                              <div className="px-2 w-[150px] ">
                                <button
                                  className="gap-1 hover:underline"
                                  onClick={() => navigate(`/activity/user?username=${e.userName}&user_id=${e.userId}&date=${date.toISOString().split("T")[0]}`)}>
                                  <div className="text-left inline">{e.userName}</div> <HiArrowTopRightOnSquare className="inline" />
                                </button>
                                <div className="flex flex-col items-end">
                                  <div className="text-xs italic font-normal">{(e.total / 8).toFixed(2)} days</div>
                                </div>
                              </div>
                            </th>
                            {e.detail.map((e, i) => {
                              return <Field key={`day-${i}`} value={e} disabled />;
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Field = ({ value = 0, onChange, invoiced, ...rest }) => {
  let bgColor = invoiced === "yes" ? "bg-[#F0F0F0]" : "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] px-0 ${bgColor} m-0`}>
      <input
        className={`border-none appearance-none min-w-[45px] text-sm w-full text-center ${bgColor} ${textColor} p-0`}
        disabled={invoiced === "yes"}
        value={value}
        min={0}
        {...rest}
        type="number"
        step="0.1"
        onChange={onChange}
        onFocus={(e) => {
          if (Number(e.target.value) === 0) {
            e.target.value = "";
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            e.target.value = 0;
          }
        }}
      />
    </th>
  );
};

export default Activity;

function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  date.setHours(0, 0, 0, 0);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

// async function exportData(entities) {
//   if (!entities.length) return;

//   const fileName = "users_availablity-" + new Date().toISOString();
//   const customColumns = [
//     "userName",
//     "job_title",
//     "daysWorked",
//     "entry_date",
//   ];

//   const columns = customColumns.map((e) => e.title);

//   const csv = [];

//   for (let j = 0; j < entities.length; j++) {
//     let obj = {};
//     for (let i = 0; i < columns.length; i++) {
//       let value = entities[j][customColumns[i]];
//       if (!value) value = "";
//       obj = { ...obj, [customColumns[i]]: value };
//     }
//     csv.push(obj);
//   }
//   initiateFileDownload(csv, fileName);
// }

// function initiateFileDownload(csv, fileName) {
//   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";
//   const ws = XLSX.utils.json_to_sheet(csv);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// }
