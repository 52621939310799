import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Logout from "./scenes/auth/logout";
import Signin from "./scenes/auth/signin";
import Activity from "./scenes/activity";
import UsersSeco2 from "./scenes/users-seco2/index";
import Bilan from "./scenes/bilans/index";
import DemoSeco2 from "./scenes/demo-seco2/index";
import UsersSecoset from "./scenes/users-secoset/index";
import Justifs from "./scenes/justificatifs/index";
import DemoSecoset from "./scenes/demo-secoset/index";
import Administration from "./scenes/administration/index";
import Account from "./scenes/account/index";
import NavAdmin from "./components/navigation";
import Projects from "./scenes/projects/index";
import Organizations from "scenes/organizations";
import Productions from "./scenes/productions/index";
import ProductionsFinished from "scenes/productions-finished";
import Dashboard from "./scenes/dashboard";

import api from "./services/api";
import useStore from "./services/zustand";
import { Loader } from "components/loader";

const AuthLayout = () => {
  const { user } = useStore();
  if (user) return <Navigate to="/" replace={true} />;
  return <Outlet />;
};

const ProtectedLayout = () => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { pathname } = window.location;

  useEffect(() => {
    api
      .get("/auth/is-loggedin")
      .then((res) => {
        if (res.ok) {
          setUser(res.data);
          setLoading(false);
        } else {
          setUser(null);
          navigate("/signin");
        }
      })
      .catch(() => setUser(null));
  }, []);

  if (loading)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader />
      </div>
    );
  if (!user && !loading) return <Navigate to="/signin" replace={true} />;

  return (
    <main className="flex bg-background w-full h-full">
      <NavAdmin />
      <section className="overflow-y-scroll flex-1 w-full h-full p-8 bg-slate-100">
        <Outlet />
      </section>
    </main>
  );
};

const AdminLayout = () => {
  const { user } = useStore();
  if (!user || !["admin", "eco-manager"].includes(user.role)) return <Navigate to="/" />;
  return <Outlet />;
};

const contextClass = {
  success: "bg-secoset-success-100 text-secoset-success-700",
  error: "bg-secoset-error-100 text-secoset-error-700",
  default: "bg-secoset-primary-100 text-secoset-primary-700",
};

function App() {
  return (
    <>
      <ToastContainer
        className="p-0"
        toastClassName={({ type }) => contextClass[type || "default"] + ` flex justify-between py-4 px-3 min-h-10 shadow-none rounded-none`}
        bodyClassName={() => "flex gap-4 text-sm"}
        progressClassName="m-0.5"
        position="top-right"
        autoClose={5000}
      />
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route element={<AuthLayout />}>
          <Route path="/signin" element={<Signin />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          {/* Seco2 route */}
          <Route path="/*" element={<Dashboard />} />
          <Route path="/users-seco2" element={<UsersSeco2 />} />
          {/* <Route path="/stats" element={<Stats />} /> */}
          <Route path="/bilans" element={<Bilan />} />
          <Route path="/demo" element={<DemoSeco2 />} />

          {/* Secoset route */}
          <Route path="/activity/:tab?" element={<Activity />} />
          <Route path="/users-secoset/*" element={<UsersSecoset />} />
          <Route path="/organizations/*" element={<Organizations />} />
          <Route path="/projects/*" element={<Projects />} />
          <Route path="/productions/*" element={<Productions />} />
          <Route path="/productions-finished/*" element={<ProductionsFinished />} />
          <Route path="/justifications" element={<Justifs />} />
          <Route path="/demo" element={<DemoSecoset />} />

          {/* Admin Route */}
          <Route element={<AdminLayout />}>
            <Route path="/administration" element={<Administration />} />
          </Route>
          {/* Account Route */}
          <Route path="/compte" element={<Account />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
