import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";

import api from "../../services/api";
import { TablePaginator } from "../../components/table";
import { Link } from "react-router-dom";
import { SECO2_APP_URL } from "../../config";

const roleLabels = {
  owner: "Propriétaire",
  admin: "Administrateur",
  user: "Utilisateur",
};

export const UserList = () => {
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [loginAsToken, setLoginAsToken] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    api
      .get("/seco2-user/all")
      .then((res) => {
        setUsers(res.data);
        setDisplayedUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    api.get("/seco2-user/loginas").then((res) => {
      setLoginAsToken(res.token);
    });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search)
      setDisplayedUsers(
        users.filter((u) => {
          return (
            (u.firstName && u.firstName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            (u.firstName && u.lastName.toLowerCase().search(search.toLowerCase()) !== -1) ||
            u.email.toLowerCase().search(search.toLowerCase()) !== -1
          );
        }),
      );
    else setDisplayedUsers(users);
    setPage(1);
  };

  const handleDelete = (id) => {
    api.delete(`/api/seco2-user/${id}`).then((res) => {
      console.log(res);
      setUsers(users.filter((u) => u._id !== id));
    });
  };

  if (!users || !loginAsToken) return <div>Chargement...</div>;

  return (
    <div className="bg-white rounded p-10">
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800">Seco2 - Utilisateurs</h1>
        <div className="flex items-center gap-4">
          <div className="flex w-full flex-wrap items-center relative">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
        </div>
      </div>
      <div className="px-4 py-2">
        <TablePaginator
          data={displayedUsers.slice((page - 1) * 10, page * 10)}
          length={displayedUsers.length}
          page={page}
          onPageChange={setPage}
          renderHeader={() => (
            <>
              <div className="px-6 w-[35%]">Utilisateurs</div>
              <div className="px-6 w-[20%]">Société</div>
              <div className="px-6 w-[15%]">Rôle</div>
              <div className="px-6 w-[15%]">Date de création</div>
              <div className="px-6 w-[15%]">Se connecter</div>
              {/* <th className="hidden px-6 table-header-title">
              Delete
            </th> */}
            </>
          )}
          renderItem={(item) => (
            <>
              <div className="px-6 w-[35%]">
                <div className="flex items-center py-2">
                  <div className="rounded-full bg-secoset-green-5 shrink-0 h-10 w-10 justify-center flex items-center text-xs text-secoset-green-900">
                    <span className="font-bold">{item.firstName ? `${item.firstName?.charAt(0).toLocaleUpperCase()}${item.lastName?.charAt(0).toLocaleUpperCase()}` : "/"}</span>
                  </div>
                  <div className="ml-8 flex flex-col">
                    <span className="font-semibold">{`${item.firstName || ""} ${item.lastName || ""}`}</span>
                    <span>{item.email}</span>
                  </div>
                </div>
              </div>
              <div className="px-6 w-[20%]">{item.companyName}</div>
              <div className="px-6 w-[15%]">{item.role}</div>
              <div className="px-6 w-[15%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
              <div className="px-6 w-[15%]">
                <a className="text-sky-400 font-bold" href={`${SECO2_APP_URL}/loginas?id=${item._id.toString()}&token=${loginAsToken}`} target="_blank">
                  Login As
                </a>
              </div>
              {/* <td className="border-t-0 flex px-2 align-middle justify-center border-l-0 border-r-0 whitespace-nowrap p-4" onClick={() => handleDelete(item.id)}>
                <RiDeleteBin5Line />
              </td> */}
            </>
          )}
        />
      </div>
    </div>
  );
};
