import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { MdOutlineInsertDriveFile } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5";
import Select from "components/select";

import api from "../../../services/api";
import { copyToClipboard } from "utils";

export default function EditProduction() {
  const [production, setProduction] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    api.get(`/productions/${id}`).then((res) => {
      if (res.ok) setProduction(res.data);
    });
  }, [id]);

  if (!production) return <h1>Chargement...</h1>;

  return (
    <div className="bg-white rounded p-10">
      <ThematicTable production={production} setProduction={setProduction} />
    </div>
  );
}
const ThematicTable = ({ production, setProduction }) => {
  return (
    <div>
      <div className="flex flex-col mt-5">
        <div className="w-full">
          <div className={`flex items-center border-b text-left text-sm font-bold bg-secoset-green-5 h-12`}>
            <div className="px-6 w-[20%]">Thématique</div>
            <div className="px-6 w-[25%]">Critère</div>
            <div className="px-6 w-[15%]">Fichiers</div>
            <div className="px-6 w-[25%]">Indicateurs</div>
            <div className="px-6 w-[15%]">Status</div>
          </div>

          <div className="overflow-y-auto">
            {production.criterias.map((c, i) => {
              const handleCriteriaChange = async (status) => {
                api.put(`/productions/${production._id.toString()}/criteria/${c.criteria._id.toString()}`, { status }).then((res) => {
                  setProduction(res.data);
                });
              };
              return (
                <div key={i} className="flex items-center border-b border-b-gray-main text-left text-xs bg-white">
                  <div className="px-6 w-[20%]">{c.thematic.title}</div>
                  <div className="px-6 w-[25%] flex items-center">
                    <img src={`/images/icons/indicators/${c.status}.svg`} />
                    <p className="ml-2">
                      {c.criteria.title} (<span>{c.criteria.kpiDescription})</span>
                    </p>
                  </div>
                  <div className="px-6 w-[15%]">
                    <div className="mb-4">
                      {c.documents?.length > 0 ? (
                        c.documents.map((doc, i) => {
                          const j = doc.lastIndexOf("/");
                          const filename = doc.substring(j + 1).replace(/%20/g, " ");
                          return (
                            <div className="flex items-center gap-1" key={i}>
                              <MdOutlineInsertDriveFile />
                              <a className="flex-1 truncate text-blue-dark underline underline-offset-1 hover:font-semibold" href={`${doc}`} download>
                                {filename}
                              </a>
                            </div>
                          );
                        })
                      ) : (
                        <span className="italic text-gray-400">aucun doc</span>
                      )}
                    </div>
                  </div>
                  <div className="px-6 w-[25%] py-2">
                    {c.criteria.indicatorDescription1 ? (
                      <p className="my-1">
                        {c.criteria.indicatorDescription1}:{" "}
                        <span className="font-bold">
                          {c.indicator1} {c.criteria.indicatorUnit1}
                        </span>
                      </p>
                    ) : (
                      <p className="my-1">
                        {c.criteria.kpiDescription}:{" "}
                        <span className="font-bold">
                          {c.indicator1} {c.criteria.indicatorUnit1}
                        </span>
                      </p>
                    )}
                    {c.criteria.indicatorDescription2 && (
                      <p className="my-1">
                        {c.criteria.indicatorDescription2}:{" "}
                        <span className="font-bold">
                          {c.indicator2} {c.criteria.indicatorUnit2}
                        </span>
                      </p>
                    )}
                    {c.criteria.indicatorDescription3 && (
                      <p className="my-1">
                        {c.criteria.indicatorDescription3}:{" "}
                        <span className="font-bold">
                          {c.indicator3} {c.criteria.indicatorUnit3}
                        </span>
                      </p>
                    )}
                    {c.indicator1 >= 0 && c.indicator2 >= 0 ? (
                      <div
                        className="bg-gray-100 p-2 flex justify-between items-center cursor-pointer"
                        onClick={() => {
                          const value = `${(100 * (c.indicator1 / (c.indicator2 || 1))).toFixed(2)}%`;
                          copyToClipboard(value);
                          toast.success(
                            <span>
                              <code>{value}</code> copié !
                            </span>,
                          );
                        }}>
                        <div>
                          {c.indicator1} sur {c.indicator2} : {(100 * (c.indicator1 / (c.indicator2 || 1))).toFixed(2)}%
                        </div>
                        <div>
                          <IoCopyOutline />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="px-6 w-[15%]">
                    <Select
                      options={[
                        {
                          value: "invalid",
                          label: "Non valide",
                        },
                        {
                          value: "error",
                          label: "Non conforme",
                        },
                        {
                          value: "todo",
                          label: "A soumettre",
                        },
                        {
                          value: "pending",
                          label: "En attente",
                        },
                        {
                          value: "ok",
                          label: "validés",
                        },
                      ]}
                      value={c.status}
                      onChange={handleCriteriaChange}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
