import React, { useEffect, useState } from "react";

import api from "../../services/api";
import { ProjectSynthesis } from "./project-synthesis";
import { ProjectList } from "./project-list";
import useStore from "../../services/zustand";

const Index = () => {
  const { user } = useStore();
  const [thematics, setThematics] = useState();
  const [criterias, setCriterias] = useState();
  const [productions, setProductions] = useState();

  const [activeEcoManager, setActiveEcoManager] = useState();

  useEffect(() => {
    if (activeEcoManager === undefined) return;
    console.log("✌️  activeEcoManager", activeEcoManager);
    api
      .get(`/labels/all`)
      .then((res) => {
        setThematics(res.thematics);
        setCriterias(res.criterias);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .post(`/productions/search`, { ecoManager: activeEcoManager })
      .then((res) => {
        setProductions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [activeEcoManager]);

  useEffect(() => {
    if (!user) return;
    if (user?.role === "eco-manager") setActiveEcoManager(user?._id);
    else setActiveEcoManager("");
  }, [user]);

  if (!thematics || !criterias || !productions) return <h1>Chargement...</h1>;

  return (
    <div className="h-full w-full px-8">
      <div className="flex items-center justify-between">
        <p className="flex text-2xl font-bold">Dashboard</p>
        <EcoManager value={activeEcoManager} handle={setActiveEcoManager} />
      </div>
      <div className="mt-10">
        <ProjectSynthesis productions={productions} />
        <div className="my-4" />
        <ProjectList productions={productions} thematics={thematics} />
      </div>
    </div>
  );
};
export default Index;

export const EcoManager = ({ value, handle }) => {
  const [ecomanagers, setEcomanagers] = useState([]);
  useEffect(() => {
    api
      .get("/secoset-user/all?role=eco-manager")
      .then((res) => {
        setEcomanagers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="relative p-4">
      <div className="flex items-center">
        <Choice name="" title="Tous" isActive={!value} onClick={() => handle("")} />
        {ecomanagers.map((em) => (
          <Choice key={em._id} name={em._id} title={em.firstName} isActive={em._id === value} onClick={() => handle(em._id)} />
        ))}
      </div>
    </section>
  );
};

export const Choice = ({ name, title, isActive, onClick }) => (
  <div onClick={() => onClick(name)}>
    <div
      className={`${
        isActive ? "hover:bg-gray-hover rounded-sm border border-blue-dark bg-white text-blue-dark" : "bg-tab-main hover:bg-tab-hover rounded-sm border-0 bg-blue-gray"
      } flex cursor-pointer items-center px-7 py-1`}>
      <p>{title}</p>
    </div>
  </div>
);
