import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { AiFillFile } from "react-icons/ai";

import { Modal } from "components/modal";
import { Paginator } from "components/paginator";
import { evaluate } from "mathjs";
import { TablePaginator } from "components/table";
import api from "../../services/api";
import { useSearchParams } from "react-router-dom";
import Select from "components/select";

const statusLabels = {
  pending: "En attente",
  answered: "Répondu",
};

const statusColors = {
  answered: "#35ECB8",
  pending: "#7DA1AE",
};

const Justifs = () => {
  const [loading, setLoading] = useState(false);
  const [discussions, setDiscussions] = useState();
  const [displayedDiscussions, setDisplayedDiscussions] = useState();
  const [searchParams] = useSearchParams();
  const newFilterValues = { status: "pending" };
  const [filterValues, setFilterValues] = useState(newFilterValues);

  const [discussionModal, setDiscussionModal] = useState({ isOpen: false, discussion: null });
  const [thematics, setThematics] = useState();
  const [productions, setProductions] = useState();
  const [page, setPage] = useState(1);

  const pageSize = 10;

  const getJustificatif = async () => {
    setLoading(true);
    api
      .get(`/admin/all${buildQuery()}`)
      .then((res) => {
        setLoading(false);
        setDiscussions(res.discussions);
        setDisplayedDiscussions(res.discussions);

        if (searchParams.get("discussion")) {
          const discussion = res.discussions.find((d) => d._id.toString() === searchParams.get("discussion"));
          setDiscussionModal({ isOpen: true, discussion });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setDisplayedDiscussions([]);
        setDiscussions([]);
      });
  };

  useEffect(() => {
    api.get("/labels/thematic").then((res) => {
      setThematics(res.thematics);
    });
    api.get("/productions/all").then((res) => {
      setProductions(res.data);
    });
  }, []);

  useEffect(() => {
    getJustificatif();
  }, [filterValues]);

  const buildQuery = () => {
    const query = Object.keys(filterValues)
      .filter((key) => filterValues[key])
      .map((key) => `${key}=${filterValues[key]}`)
      .join("&");
    return `${query ? `?${query}` : ""}`;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search)
      setDisplayedDiscussions(
        discussions.filter((j) => {
          const thematic = thematics.find((t) => t._id.toString() === j.criteriaId.thematic.toString());
          return (
            j.production.title.toLowerCase().search(search.toLowerCase()) !== -1 ||
            thematic.title.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.criteriaId.title.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.production.organization.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.userId.firstName.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.userId.lastName.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.userId.email.toLowerCase().search(search.toLowerCase()) !== -1 ||
            j.criteriaId.actionTitle.toLowerCase().search(search.toLowerCase()) !== -1
          );
        }),
      );
    else setDisplayedDiscussions(discussions);
    setPage(1);
  };

  if (!discussions || !displayedDiscussions || !thematics || !productions) return <div>Chargement...</div>;

  return (
    <div className="bg-white rounded p-10">
      <DiscussionModal settings={discussionModal} setSettings={setDiscussionModal} thematics={thematics} getJustificatif={getJustificatif} />
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800">Secoset - Justificatifs</h1>
        <div className="flex items-center gap-4">
          <div className="flex w-full flex-wrap items-center relative">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
          {displayedDiscussions.length > 1 ? displayedDiscussions.length + " justifications" : displayedDiscussions.length + " justification"}
        </span>
        <div className="w-[360px]">
          <Select
            options={[
              {
                value: "pending",
                label: "En attente",
              },
              {
                value: "answered",
                label: "Répondu",
              },
              {
                value: "ignored",
                label: "Ignoré",
              },
              {
                value: "",
                label: "Tous",
              },
            ]}
            value={filterValues.status}
            placeholder="Status"
            onChange={(value) => {
              if (filterValues.status === value) setFilterValues({ ...filterValues, status: null });
              else setFilterValues({ ...filterValues, status: value });
            }}
          />
        </div>
        <div className="w-[360px]">
          <Select
            options={productions.map((e) => ({ label: e.title, value: e._id.toString() }))}
            value={filterValues.production}
            placeholder="Projet"
            onChange={(value) => {
              if (filterValues.production === value) setFilterValues({ ...filterValues, production: null });
              else setFilterValues({ ...filterValues, production: value });
            }}
          />
        </div>
      </div>
      <TablePaginator
        loading={loading}
        data={displayedDiscussions.slice((page - 1) * pageSize, page * pageSize)}
        length={displayedDiscussions.length}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[35%]">Document</div>
            <div className="px-6 w-[20%]">Projet</div>
            <div className="px-6 w-[25%]">Utilisateur</div>
            <div className="px-6 w-[10%]">Statut</div>
            <div className="px-6 w-[10%]">Date</div>
          </>
        )}
        renderItem={(item) => {
          const thematic = thematics.find((t) => t._id.toString() === item.criteriaId.thematic.toString());
          return (
            <>
              <div className="px-6 w-[35%] cursor-pointer" onClick={() => setDiscussionModal({ isOpen: true, discussion: item })}>
                <div className="text-xs truncate">{thematic.title}</div>
                <div className="text-xs truncate">{item.criteriaId.title}</div>
                <div className="text-xs truncate text-secoset-green-900 font-bold">{item.criteriaId.actionTitle}</div>
              </div>
              <div className="px-6 w-[20%] truncate">{item.production.title}</div>
              <div className="px-6 w-[25%]">
                <div className="flex flex-row items-center">
                  <div className="rounded-full bg-secoset-green-5 h-10 w-10 shrink-0 justify-center flex items-center text-xs text-secoset-green-900">
                    <span className="font-bold ">{`${item.userId.firstName?.charAt(0).toLocaleUpperCase()}${item.userId.lastName?.charAt(0).toLocaleUpperCase()}`}</span>
                  </div>
                  <div className="ml-2 flex flex-col overflow-hidden">
                    <span className="font-semibold truncate">{`${item.userId.firstName} ${item.userId.lastName}`}</span>
                    <span className="truncate">{item.userId.email}</span>
                  </div>
                </div>
              </div>
              <div className="px-6 w-[10%] truncate" style={{ color: statusColors[item.status] }}>
                {statusLabels[item.status] || item.status}
              </div>

              <div className="px-6 w-[10%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
            </>
          );
        }}
      />
    </div>
  );
};

export default Justifs;

const DiscussionModal = ({ settings, setSettings, thematics, getJustificatif }) => {
  if (!settings.discussion) return null;
  const [history, setHistory] = useState();
  const discussion = settings.discussion;
  const criteria = settings.discussion.criteriaId;
  const thematic = thematics.find((t) => t._id.toString() === criteria.thematic.toString());
  const values = discussion.production.criterias.find((c) => c.criteria === criteria._id.toString());

  const [status, setStatus] = useState(settings.discussion.status);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get(`/admin/history/${discussion.production._id}?criteriaId=${criteria._id}`).then((res) => {
      console.log("tes", res);
      setHistory(res.history);
    });
  }, [discussion, criteria._id]);

  const handleSubmit = async () => {
    try {
      if (!status) return setError("Veuillez sélectionner un statut");
      if (status !== "ok" && !message) return setError("Veuillez saisir un message");
      if (loading) return;
      setLoading(true);
      await api.post(`/admin/answer/${discussion._id.toString()}`, {
        message,
        status,
      });
      setLoading(false);
      getJustificatif();
      setSettings({ isOpen: false, discussion: null });
    } catch (e) {
      setLoading(false);
      setError("Une erreur est survenue");
      console.error(e);
    }
  };
  const handleIgnored = async () => {
    try {
      if (!confirm("Êtes-vous sûr de vouloir ignorer cette justification ?")) return;
      if (loading) return;
      setLoading(true);
      await api.put(`/admin/discussion/${discussion._id.toString()}`, {
        status: "ignored",
      });
      setLoading(false);
      getJustificatif();
      setSettings({ isOpen: false, discussion: null });
    } catch (e) {
      setLoading(false);
      setError("Une erreur est survenue");
      console.error(e);
    }
  };

  const computedValue = getAverage(criteria.kpiRelation, values.indicator1, values.indicator2, values?.indicator3).value;

  return (
    <Modal isOpen={settings.isOpen} onClose={() => setSettings({ isOpen: false, discussion: null })} className="w-2/3 -translate-x-[40%]">
      <div className=" px-10 overflow-y-auto pb-8 max-h-[90vh]">
        <h2 className="font-bold text-slate-700 text-2xl mb-6">Validation de documents</h2>
        <div className="bg-[#EFF6F7] p-2 flex flex-col gap-2">
          <div className="bg-white p-4">
            <h3 className="font-bold text-xl mb-3">Projet : {discussion.title}</h3>
            <div className="my-4">
              <LabelStatusSynthesis production={discussion.production} />
            </div>
            <LabelStatusDetails thematics={thematics} production={discussion.production} />
          </div>
          <div className="bg-white p-4">
            <h3 className="font-bold text-xl mb-3">Informations soumises</h3>
            <div className="mt-2 text-sm">Thématique : {thematic.title}</div>
            <div className="text-sm">Critère : {discussion.criteriaId.title}</div>
            <div className="text-sm font-bold">Document : {discussion.criteriaId.actionTitle}</div>
            {history && history.length !== 0 && (
              <div>
                <div className="font-bold mb-2 mt-4">Historique des échanges</div>
                {history.map((discussion, i) => (
                  <DiscussionRow key={i} discussion={discussion} />
                ))}
              </div>
            )}
          </div>
          <div className="bg-white p-4">
            <h3 className="font-bold text-xl mb-3">Comment valider ce critère ?</h3>
            <div className="text-sm mt-2">{discussion.criteriaId.actionDescription}</div>
            <div className="font-bold mt-4">Points de contrôle :</div>
            <div className="flex flex-col gap-2 mt-2">
              {criteria.checkpoints.map((checkpoint, i) => (
                <div key={checkpoint} className="flex flex-row  items-center justify-start  ">
                  <img src={`/images/icons/indicators/check.svg`} alt={"check"} />
                  <div className="text-sm">{checkpoint}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-4">
            <h3 className="font-bold text-xl mb-3">Indicateur</h3>
            <p className="text-sm"></p>
            <p className="text-sm">
              Indicateur 1 - {criteria.indicatorDescription1}: <span className="font-semibold">{`${values.indicator1} ${criteria.indicatorUnit1}`}</span>
            </p>
            {criteria.indicatorDescription2 && (
              <p className="text-sm">
                Indicateur 2 - {criteria.indicatorDescription2}: <span className="font-semibold">{`${values.indicator2} ${criteria.indicatorUnit2}`}</span>
              </p>
            )}
            {criteria.indicatorDescription3 && (
              <p className="text-sm">
                Indicateur 3 - {criteria.indicatorDescription3}: <span className="font-semibold">{`${values.indicator3} ${criteria.indicatorUnit3}`}</span>
              </p>
            )}
            {computedValue && (
              <p className="text-sm">
                Moyenne : <span className="font-bold">{computedValue + " " + criteria.kpiUnit}</span>
              </p>
            )}
            <div className="text-base mt-2"></div>
          </div>
          <div className="bg-white p-4">
            <div className="font-bold text-xl">Fichiers soumis par l'utilisateur</div>
            <div className="mt-2">
              {values.documents.map((doc, i) => {
                const text = doc.match(/\/([^\/?#]+)[^\/]*$/);
                const docName = text[1].match(/[^%]*/);
                return (
                  <div className="flex flex-row items-center gap-2" key={i + "doc"}>
                    <AiFillFile className="text-blue-dark" />
                    <a className="text-blue-dark underline underline-offset-1" href={`${doc}`} download>
                      {docName[0]}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-white p-4">
            <div className="font-bold text-xl">Réponse</div>
            <div className="flex flex-row items-center mt-2">
              <div className="flex flex-col w-full">
                <div className="flex flex-row gap-2 items-start mt-2">
                  <div className=" font-medium text-sm mr-4">Validation</div>

                  <div className="flex flex-row items-center gap-8">
                    <div className="flex flew-row items-center gap-2 cursor-pointer" onClick={() => setStatus("ok")}>
                      <input type="radio" name="status" checked={status === "ok"} onChange={() => null} />
                      <div>Oui</div>
                    </div>
                    <div className="flex flew-row items-center gap-2 cursor-pointer" onClick={() => setStatus("todo")}>
                      <input type="radio" name="status" label="Laisser en attente" checked={status === "todo"} onChange={() => null} />
                      <div>Laisser en attente</div>
                    </div>
                    <div className="flex flew-row items-center gap-2 cursor-pointer" onClick={() => setStatus("error")}>
                      <input type="radio" name="status" checked={status === "error"} onChange={() => null} />
                      <div>Non Conforme</div>
                    </div>
                    <div className="flex flew-row items-center gap-2 cursor-pointer" onClick={() => setStatus("invalid")}>
                      <input type="radio" name="status" checked={status === "invalid"} onChange={() => null} />
                      <div>Non Validé</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-row gap-2 items-start mt-2">
                    <div className=" font-medium mr-4 text-sm w-fit">Message personnalisé</div>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} className="w-full border-[1px] p-1" />
                  </div>
                </div>

                {error !== "" && <div className="text-red text-sm text-center mt-2">{error}</div>}
                <div className="flex flew-row items-center gap-4 w-full justify-center mt-4">
                  <button className="empty-button" onClick={() => setSettings({ isOpen: false, discussion: null })}>
                    Annuler
                  </button>
                  <button className="blue-button" onClick={handleSubmit}>
                    {loading ? "..." : "Valider"}
                  </button>
                </div>
                <div className="flex w-full items-center my-4">
                  <div className="flex-1 border border-gray-200" />
                  <div className="mx-4 text-gray-500">ou</div>
                  <div className="flex-1 border border-gray-200" />
                </div>
                <div className="flex flew-row items-center gap-4 w-full justify-center">
                  <button className="red-button" onClick={handleIgnored}>
                    Ignorer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const LabelStatusSynthesis = ({ production }) => {
  const { criteriaDone, criterias } = production;
  return (
    <div className={`flex w-60 flex-col gap-4 rounded-sm border p-4`}>
      <p className="flex justify-center font-bold text-blue-semi-dark">{` ${((criteriaDone / criterias.length) * 100).toFixed(2)} % complétés (${criteriaDone}/${
        criterias.length
      })`}</p>

      {/* <div>
        {statsByStatus?.map((stat, i) => (
          <LabelStatByStatus key={i} stat={stat} total={total} />
        ))}
      </div> */}

      <div className="relative">
        <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
          <div style={{ width: `${(criteriaDone / criterias.length) * 100}%` }} className="h-full bg-blue-dark" />
        </div>
      </div>
    </div>
  );
};

const LabelStatusDetails = ({ thematics, production }) => {
  return (
    <div className="flex flex-wrap justify-around gap-4">
      {thematics.map((_thematic, i) => (
        <ThematicStatusDetails
          key={i}
          thematic={_thematic}
          index={i}
          production={production}
          criterias={production.criterias.filter(({ thematic }) => _thematic._id === thematic.toString())}
        />
      ))}
    </div>
  );
};

const ThematicStatusDetails = ({ thematic, production, criterias }) => {
  return (
    <div className="flex justify-between min-w-[350px] flex-[33%]">
      <div className="flex items-center">
        <div className="relative flex items-center rounded-full shrink-0" style={{ backgroundColor: thematic.color }}>
          <img className="z-30 h-8 w-8" src={`/images/icons/thematics/${thematic.icon}.svg`} alt="Secoset" />
        </div>

        <p className="hxr ml-5 w-60 text-sm">{thematic.title}</p>
      </div>
      <div className="flex  items-center">
        <img src={`/images/icons/indicators/${criterias[0].status}.svg`} />
        <img src={`/images/icons/indicators/${criterias[1].status}.svg`} />
      </div>
    </div>
  );
};

export const DiscussionRow = ({ discussion }) => {
  const dateLocale = new Date(discussion.createdAt).toLocaleDateString();
  const infosByAction = {
    Soumission: {
      icon: "check",
      text: "Soumission des informations",
    },
    Validation: {
      icon: "ok",
      text: "Validation",
    },
    Refus: {
      icon: "error",
      text: "Refus",
    },
    Attente: {
      icon: "pending",
      text: "Laissé en attente",
    },
    Invalidation: {
      icon: "invalid",
      text: "Non validé",
    },
  };
  const infos = infosByAction[discussion.action ? discussion.action : "Soumission"];
  return (
    <div className="flex flex-row items-start justify-start">
      <img src={`/images/icons/indicators/${infos.icon}.svg`} alt={"check"} />
      <div className="flex flex-col justify-start">
        <div className="text-sm">
          {infos.text}, le {dateLocale}, par {discussion.userId.firstName + " " + discussion.userId.lastName}
        </div>
        {discussion.message && (
          <div className="mb-2">
            <p className="text-sm">
              {discussion.firstName + " " + discussion.lastName + discussion?.fromSecoya === true ? " (de Secoya)" : ""}
              {/* <Text index={["documentExchangeModal", "from_secoya"]} /> */}
            </p>
            <p className="text-sm">{discussion.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const getAverage = (relation, a, b, c) => {
  try {
    if (a && !b && !c) return { value: parseFloat(a), digits: 2 };
    if (!relation) return { value: null, digits: 0 };
    // Extract values from the string using regular expressions
    const matches = relation.match(/\((.*?)\)\.(.*)/);
    const formula = matches[1];
    const digits = parseInt(matches[2] || 0);

    // Calculate the average
    if (!a && !b && !c) return { value: null, digits };

    if (formula.includes("a") && !a) return { value: null, digits };
    if (formula.includes("b") && !b) return { value: null, digits };
    if (formula.includes("c") && !c) return { value: null, digits };

    const value = evaluate(formula.replace("a", a).replace("b", b).replace("c", c));
    return { value, digits };
  } catch (error) {
    return { value: null, digits: 0 };
  }
};
