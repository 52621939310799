import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GoGear } from "react-icons/go";
import { CgLogOut } from "react-icons/cg";

import api from "../services/api";
import { SECOSET_APP_URL, SECO2_APP_URL, SECOSET_DEMO_USERID, SECO2_DEMO_USERID } from "../config";
import { classNames } from "../utils";

export default function NavAdmin() {
  const [loginAsSecosetToken, setLoginAsSecosetToken] = useState(null);
  const [loginAsSeco2Token, setLoginAsSeco2Token] = useState(null);

  useEffect(() => {
    api
      .get("/secoset-user/loginas")
      .then((res) => setLoginAsSecosetToken(res?.token))
      .catch((err) => console.error(err));
    api
      .get("/seco2-user/loginas")
      .then((res) => setLoginAsSeco2Token(res?.token))
      .catch((err) => console.error(err));
  }, []);

  return (
    <nav className="h-full w-60 bg-secoset-green-900">
      <div className="w-full h-full flex flex-col justify-between overflow-y-auto">
        <div className="w-full">
          <h1 className="text-white uppercase font-bold text-xs m-3">SECOYADMIN</h1>
          <Item label="Tableau de bord" to="/" />
          <Item label="Activities" to="/activity" />
          <Item label="Projets internes" to="/projects" />

          <h1 className="text-white uppercase font-bold text-xs m-3">Seco2</h1>
          <ul className="w-full">
            <Item label="Utilisateurs" to="/users-seco2" />
            {/* 
            <li className={`py-2 pl-14 flex items-start text-white font-semibold hover:bg-secoset-green-5/20`}>
              <NavLink className="w-full h-full" to="/stats">
                Statistiques
              </NavLink>
            </li>
            <li className={`py-2 pl-14 flex items-start text-white font-semibold hover:bg-secoset-green-5/20`}>
              <NavLink className="w-full h-full" to="/bilans">
                Bilans
              </NavLink>
            </li> */}
            {/* {loginAsSeco2Token && (
              <li className={`py-2 pl-14 flex items-start text-white font-semibold hover:bg-secoset-green-5/20`}>
                <a className="w-full h-full" href={`${SECO2_APP_URL}/loginas?id=${SECO2_DEMO_USERID}&token=${loginAsSeco2Token}`} target="_blank">
                  Démo
                </a>
              </li>
            )} */}
          </ul>
          <h1 className="text-white uppercase font-bold text-xs m-3">Secoset</h1>
          <ul className="w-full">
            <Item label="Utilisateurs" to="/users-secoset" />
            <Item label="Sociétés" to="/organizations" />
            <Item label="Projets" to="/productions" />
            <Item label="Projets finis" to="/productions-finished" />
            <Item label="Justificatifs" to="/justifications" />

            {loginAsSecosetToken && (
              <li className={`py-3 pl-14 flex items-start text-white font-semibold hover:bg-secoset-green-5/20`}>
                <a className="w-full h-full" href={`${SECOSET_APP_URL}/loginas?id=${SECOSET_DEMO_USERID}&token=${loginAsSecosetToken}`} target="_blank">
                  Démo
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="w-full mt-2 mb-6">
          <ul className="w-full">
            {/* {user.role === "admin" ? (<CustomNavLinkAdmin to="*administration" icons={<GoGear/>}>Admin</CustomNavLinkAdmin>) : null} */}
            <li className="py-2 pl-6 w-full text-white font-semibold hover:bg-secoset-green-5/20">
              <NavLink className="flex items-center" to="administration" icons={<GoGear />}>
                <GoGear className="mr-6" />
                Administration
              </NavLink>
            </li>
            {/* <li className="py-2 pl-6 w-full text-white font-semibold hover:bg-secoset-green-5/20">
              <NavLink className="flex items-center" to="administration" icons={<GoGear />}>
                <VscAccount className="mr-6" />
                Mon compte
              </NavLink>
            </li> */}
            <li className="py-2  pl-6 w-full text-white font-semibold hover:bg-secoset-green-5/20">
              <NavLink className="flex items-center" to="/logout">
                <CgLogOut className="mr-6" />
                Déconnexion
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

const Item = ({ label, to }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(isActive ? "bg-secoset-green-5/20 text-white" : "text-white", "py-2 pl-14 flex items-start font-semibold hover:bg-secoset-green-5/20")
      }
      to={to}>
      {label}
    </NavLink>
  );
};
