export const PRODUCTION_PLAN = {
  demo: "Démo",
  autonomy: "Pilotage",
  supported: "Labelisation",
};

export const PRODUCTION_PLAN_COLORS = {
  autonomy: "#7879E6",
  demo: "#E66136",
  supported: "#DF988A",
};
