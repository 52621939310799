import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { Formik, Form } from "formik";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { TablePaginator } from "../../components/table";
import ProgressBar from "components/ProgressBar";
import { Modal } from "components/modal";

import api from "../../services/api";

export const ProductionList = () => {
  const [projects, setProjects] = useState();
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    api
      .get(`/projects/all`)
      .then((res) => {
        setProjects(res.data);
        setDisplayedProjects(res.data);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isCreateModalOpen]);

  const handleSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    if (search) setDisplayedProjects(projects.filter((u) => u.title.toLowerCase().search(search.toLowerCase()) !== -1));
    else setDisplayedProjects(projects);
    setPage(1);
  };

  if (!projects) return <div>Chargement...</div>;
  return (
    <div className="bg-white rounded p-10">
      <CreateModal open={isCreateModalOpen} setOpen={setIsCreateModalOpen} />
      <div className="flex items-center justify-between mb-10">
        <h1 className="font-bold text-4xl text-cyan-800 w-fit">Secoset - Projets</h1>
        <div className="flex items-center gap-4">
          <div className="flex flex-wrap items-center relative w-[360px]">
            <span className="z-10 absolute text-slate-700 bg-transparent items-center justify-center w-8 p-3 left-2">
              <BsSearch />
            </span>
            <input onChange={handleSearch} placeholder="Rechercher..." className="input pl-10" />
          </div>
          <button className="blue-button whitespace-nowrap" onClick={() => setIsCreateModalOpen(true)}>
            Créer un project
          </button>
        </div>
      </div>
      <div className="flex items-center gap-5 mb-4">
        <span className="bg-secoset-green-5 rounded-2xl w-fit p-3.5 font-medium text-sm text-secoset-green-700">
          {projects.length > 1 ? projects.length + " projets" : projects.length + " projet"}
        </span>
      </div>

      <TablePaginator
        itemHeight="h-full"
        data={displayedProjects.slice((page - 1) * 10, page * 10)}
        length={displayedProjects.length}
        page={page}
        onPageChange={setPage}
        renderHeader={() => (
          <>
            <div className="px-6 w-[20%]">Projet</div>
            <div className="px-6 w-[15%]">Statut</div>
            <div className="px-6 w-[15%]">Date</div>
            <div className="px-6 flex-1">Budget</div>
          </>
        )}
        renderItem={(item) => (
          <>
            <div className="px-6 w-[20%] py-4">
              <Link className="w-full font-bold" to={`/projects/${item._id.toString()}`}>
                {item.title}
              </Link>
            </div>
            <div className="px-6 w-[15%]">{item.statuts}</div>
            <div className="px-6 w-[15%]">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
            <div className="px-6 flex-1">
              <Calculations production={item} />
            </div>
          </>
        )}
      />
    </div>
  );
};

const Calculations = ({ production: project }) => {
  const [activities, setActivities] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?projectId=${project._id.toString()}`);
      setActivities(data);
    })();
  }, []);

  if (!activities) return <div className="p-4 text-sm">Chargement...</div>;

  const cost = activities.reduce((acc, cur) => acc + cur.cost, 0);

  if (!project.budget) return <div className="text-sm font-bold">Consumption: {cost}€</div>;

  return <ProgressBar percentage={((cost || 0) * 100) / (project.budget || 1)} max={project.budget} value={cost} />;
};

const CreateModal = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.title)) errors.title = "Le titre est requis";
    setErrors(errors);
    return !errors.title;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    api.post("/projects", values).then((res) => {
      navigate(`/projects/${res.data._id.toString()}`);
      toast.success("Utilisateur modifié");
    });
  };

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
      <div className="p-8">
        <h1 className="text-2xl text-center font-semibold">Créer un projet interne</h1>
        <Formik initialValues={{ title: "" }} onSubmit={handleSubmit}>
          {({ values, errors, handleChange }) => (
            <Form className="flex flex-col gap-4 px-16 pt-8">
              <div className="flex flex-col">
                <label className="mb-2 font-bold text-slate-500 text-sm" htmlFor="title">
                  Titre
                </label>
                <input className="input" name="title" value={values.title} onChange={handleChange} />
                {errors.title && <div className="flex items-center text-sm text-red-main">{errors.title}</div>}
              </div>

              <div className="flex justify-end">
                <button type="submit" className="blue-button mt-10 w-56" disabled={Object.keys(errors).length !== 0}>
                  Creer
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
