import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

import SelectMonth from "../../components/selectMonth";
import SelectUser from "../../components/selectUser";

import api from "../../services/api";
import useStore from "../../services/zustand";

import { getDaysInMonth } from "../../utils";

const Activity = () => {
  const [date, setDate] = useState(null);
  const [values, setValues] = useState(null);

  const { user } = useStore();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const username = params.get("username");
    const userId = params.get("user_id");
    const date = params.get("date");
    if (date) setDate(new Date(date));

    // todo get the full data from the user
    if (userId) setValues((_u) => ({ ..._u, _id: userId }));
    if (username) setValues((_u) => ({ ..._u, firstName: username, lastName: "" }));
    if (!userId) setValues(user);
  }, []);

  return (
    <div className="w-screen md:w-full">
      <div className="flex flex-wrap gap-5 p-2">
        <SelectUser value={values} onChange={setValues} />
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
      </div>
      {date && user && <Activities date={new Date(date)} user={values} />}
    </div>
  );
};

const Activities = ({ date, user }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/activity?date=${date.getTime()}&userId=${user._id}`);
      setActivities(data);
    })();
  }, [date, user]);

  const days = getDaysInMonth(date.getMonth(), date.getFullYear());

  const onAddActivities = async (project) => {
    const found = activities.find((a) => a.projectId === project._id);
    if (found) return toast.error(`Project ${project.name} already added !`);
    const detail = days.map((e) => ({ date: e.date, value: 0 }));
    const collectionName = project.criterias ? "production" : "project";
    api
      .post(`/activity`, { projectId: project._id, collectionName, userId: user._id, date: date.getTime(), detail })
      .then((res) => {
        if (res.ok) setActivities([...activities, res.data]);
        else toast.error(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function onSave() {
    for (let i = 0; i < activities.length; i++) {
      const { code } = await api.post(`/activity`, { ...activities[i] });
      if (code) {
        toast.error(`${activities[i].projectTitle} not saved : ${code}`);
      } else {
        toast.success(`Saved ${activities[i].projectTitle}`);
      }
    }
  }

  async function onDelete(i) {
    if (!window.confirm("Are you sure ?")) return;
    await api.delete(`/activity/${activities[i]._id}`);

    const newActivities = [...activities];
    newActivities.splice(i, 1);
    setActivities(newActivities);
    toast.success(`Deleted `);
  }

  function onUpdateValue(i, j, value) {
    if (!user.tjm) return toast.error("Please contact an admin to set your TJM");
    // if (!user.tjms) return toast.error("Please contact an admin to set your TJMS");

    const n = [...activities];

    n[i].detail[j].value = value;
    n[i].total = n[i].detail.reduce((acc, b) => acc + b.value, 0);

    /// 0 if this project
    if (n[i].project === "Unavailable") {
      n[i].cost = 0;
      n[i].value = 0;
    } else {
      n[i].cost = (n[i].total / 8) * user.tjm;
    }

    setActivities(n);
  }

  const getWorkingDays = () => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return acc + 1;
    }, 0);
  };

  const getTotal = () => (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);

  return (
    <div className="flex flex-wrap py-3 gap-4 text-black ">
      <div className="w-screen md:w-full p-2">
        <div className="mt-2 rounded-xl bg-[#fff] ">
          <div className="overflow-scroll relative">
            <div>
              <table className="w-full">
                <thead className="sticky top-0">
                  <tr className="p-0">
                    <th className="border-t border-b border-r text-base font-bold text-[#212325] text-left sticky left-0 bg-white">Projects</th>
                    {days.map((e) => {
                      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                      const _date = new Date(e);
                      const day = _date.getDay();
                      const weekday = days[day];
                      const date = _date.getDate();
                      return (
                        <th
                          className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                          key={e}
                          day={day}>
                          <div>{weekday}</div>
                          <div>{date}</div>
                        </th>
                      );
                    })}
                    <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-white`} />
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b border-r border-[#E5EAEF]">
                    <th className="px-2 sticky left-0 bg-white ">
                      <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                        <div>{`${getTotal()} / ${getWorkingDays()} days`}</div>
                      </div>
                    </th>
                    {days.map((e, i) => {
                      const v = activities.reduce((acc, a) => {
                        if (!a.detail[i]) return acc;
                        return acc + a.detail[i].value;
                      }, 0);
                      return <Field key={`day-${i}`} value={v} disabled />;
                    })}
                  </tr>
                  {activities
                    .sort((a, b) => (a.projectTitle < b.projectTitle ? -1 : 1))
                    .map((e, i) => {
                      return (
                        <React.Fragment key={e.projectId}>
                          <tr className="border-t border-b border-r border-[#E5EAEF]" key={`1-${e._id}`}>
                            <th className=" border-t border-b border-r text-[12px] font-bold text-[#212325] text-left sticky left-0 bg-white">
                              <div className="flex flex-1 items-center justify-between gap-1 px-2 w-[150px]">
                                <div className="flex flex-1 items-center justify-start gap-1">
                                  <div>{e.projectTitle}</div>
                                </div>
                                <div className="flex flex-col items-end">
                                  <div className="text-xs italic font-normal">{(e.total / 8).toFixed(2)} days</div>
                                  <div className="text-[10px] italic font-normal">{(((e.total / 8).toFixed(2) / getTotal()) * 100).toFixed(2)}%</div>
                                </div>
                              </div>
                            </th>
                            {e.detail.map((f, j) => {
                              return (
                                <Field
                                  key={`${e.projectTitle} ${j}`}
                                  freezed={e.freezed}
                                  value={f.value || 0}
                                  onChange={(a) => onUpdateValue(i, j, parseFloat(a.target.value || 0))}
                                />
                              );
                            })}
                            <th className={`border border-[#E5EAEF] py-[6px]`}>
                              <div className={`flex justify-center cursor-pointer text-xl hover:text-red-500`}>
                                <MdDeleteForever onClick={() => onDelete(i)} />
                              </div>
                            </th>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="w-[150px] text-[12px] text-[#212325] px-[10px] py-2 text-left sticky left-0 bg-white">
              <SelectProject disabled={!activities.map((e) => e.projectTitle)} value="" onChange={(e) => onAddActivities(e)} />
            </div>
            <button className="m-3 w-[82px] h-[48px] py-[12px] px-[22px] bg-[#0560FD] text-[16px] font-medium text-[#fff] rounded-[10px] sticky left-3" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectProject = ({ value, onChange, disabled }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/activity/projects");
      setProjects(data);
    })();
  }, []);

  return (
    <select
      className="w-40 p-2 bg-white font-medium  rounded-md border border-gray-500"
      value={value}
      onChange={(e) => {
        const f = projects.find((f) => e.target.value === f._id);
        onChange(f);
      }}
      disabled={disabled}>
      <option value="">Select Project</option>
      {projects.map((e) => {
        return (
          <option key={e._id} value={e._id}>
            {e.title || e.name}
          </option>
        );
      })}
    </select>
  );
};

const Field = ({ value = 0, onChange, freezed, ...rest }) => {
  let bgColor = freezed === "yes" ? "bg-[#F0F0F0]" : "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] px-0 ${bgColor} m-0`}>
      <input
        className={`border-none min-w-[45px] w-full text-center ${bgColor} ${textColor} p-0`}
        disabled={freezed === "yes"}
        value={parseFloat(value)}
        min={0}
        {...rest}
        type="number"
        step="0.1"
        onChange={onChange}
        onFocus={(e) => {
          if (Number(e.target.value) === 0) {
            e.target.value = "";
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            e.target.value = 0;
          }
        }}
      />
    </th>
  );
};

export default Activity;
